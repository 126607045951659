import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageLoaded = () => {
  const { search } = useLocation();
  const accountReferenceFromUrl = new URLSearchParams(search).get('accountReference');
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true);
      if (accountReferenceFromUrl) {
        // If accountReference in URL save it to session storage to get property details with it.
        window.sessionStorage.setItem('accountReferenceFromUrl', accountReferenceFromUrl);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pageLoaded, accountReferenceFromUrl]);

  return pageLoaded;
};

export default usePageLoaded;
