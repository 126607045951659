import { getLongDateFormat } from '../../../common/functions';

export const LastPaymentTextMapper = (lastPaymentDate, paymentMethod) => {
  paymentMethod = paymentMethod.toLowerCase();
  switch (paymentMethod) {
    case 'Direct Debit Budget Plan Monthly'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay every month by Direct Debit.',
      };
    }
    case 'Direct Debit Budget Plan Fortnightly'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You make 2 payments every month by Direct Debit.',
      };
    }
    case 'Direct Debit Budget Plan Weekly'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay every week by Direct Debit.',
      };
    }
    case 'Direct Debit Per Bill Quarterly'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay every quarter by Direct Debit.',
      };
    }
    case 'Cash Budget Plan Monthly'.toLowerCase():
    case 'Statement Budget Plan Monthly'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay every month.',
      };
    }
    case 'Cash Budget Plan Fortnightly'.toLowerCase():
    case 'Statement Budget Plan Fortnightly'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You make 2 payments every month.',
      };
    }
    case 'Cash Budget Plan Weekly'.toLowerCase():
    case 'Statement Budget Plan Weekly'.toLowerCase():
    case 'Statement Weekly 52'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay every week.',
      };
    }
    case 'Cash Per Bill Quarterly'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay every quarter.',
      };
    }
    case 'Direct Debit Monthly 08'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You make 8 monthly payments by Direct Debit.',
      };
    }
    case 'Direct Debit Monthly 09'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You make 9 monthly payments by Direct Debit.',
      };
    }
    case 'Direct Debit Monthly 10'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You make 10 monthly payments by Direct Debit.',
      };
    }
    case 'Direct Debit Monthly 11'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You make 11 monthly payments by Direct Debit.',
      };
    }
    case 'Direct Debit Monthly 12'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You make 12 monthly payments by Direct Debit.',
      };
    }
    case 'Direct Debit Half Yearly 02'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You make 2 half yearly payments by Direct Debit.',
      };
    }
    case 'Direct Debit Annually 01'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You make an annual payment by Direct Debit.',
      };
    }
    case 'Direct Debit Fortnightly 24'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You make 2 payments every month by direct debit.',
      };
    }
    case 'Direct Debit Weekly 52'.toLowerCase(): {
      return {
        paymentDate: `Taken on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay every week by Direct Debit.',
      };
    }
    case 'Cash Monthly 08'.toLowerCase():
    case 'Statement Monthly 08'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay 8 monthly instalments.',
      };
    }
    case 'Cash Monthly 09'.toLowerCase():
    case 'Statement Monthly 09'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay 9 monthly instalments.',
      };
    }
    case 'Cash Monthly 10'.toLowerCase():
    case 'Statement Monthly 10'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay 10 monthly instalments.',
      };
    }
    case 'Cash Monthly 11'.toLowerCase():
    case 'Statement Monthly 11'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay 11 monthly instalments.',
      };
    }
    case 'Cash Monthly 12'.toLowerCase():
    case 'Statement Monthly 12'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay 12 monthly instalments.',
      };
    }
    case 'Cash Fortnightly 24'.toLowerCase():
    case 'Statement Fortnightly 24'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You make 2 payments every month.',
      };
    }
    case 'Cash Weekly 52'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay every week.',
      };
    }
    case 'Cash Half Yearly 02'.toLowerCase():
    case 'Statement Half Yearly 02'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You pay 2 half yearly instalments.',
      };
    }
    case 'Statement Annually 01'.toLowerCase(): {
      return {
        paymentDate: `Your payment was received on ${getLongDateFormat(lastPaymentDate)}.`,
        paymentFrequency: 'You make an annual payment.',
      };
    }
    default: {
      return {
        paymentDate: '',
        paymentFrequency: '',
      };
    }
  }
};
