export const APP_ACTIONS = {
  UPDATE_ENCRYPTED_REFERENCE_NUMBER: 'APP_ACTIONS.UPDATE_ENCRYPTED_REFERENCE_NUMBER',
  UPDATE_JWT: 'APP_ACTIONS.UPDATE_JWT',
  UPDATE_AUTH_CODE: 'APP_ACTIONS.UPDATE_AUTH_CODE',
  UPDATE_IS_AUTH: 'APP_ACTIONS.UPDATE_IS_AUTH',
  UPDATE_IS_EDITING: 'APP_ACTIONS.UPDATE_IS_EDITING',
  UPDATE_CHANNEL: 'APP_ACTIONS.UPDATE_CHANNEL',
  UPDATE_FORM_STARTED: 'APP_ACTIONS.UPDATE_FORM_STARTED',
  UPDATE_SELECTED_PROPERTY_REFERENCE: 'APP_ACTIONS.UPDATE_SELECTED_PROPERTY_REFERENCE',
  RESET_APP: 'APP_ACTIONS.RESET_APP',
  UPDATE_LOADING_MESSAGE: 'UPDATE_LOADING_MESSAGE',
};

export const updateEncryptedReferenceNumber = (encryptedReferenceNumber) => ({
  encryptedReferenceNumber,
  type: APP_ACTIONS.UPDATE_ENCRYPTED_REFERENCE_NUMBER,
});

export const updateJwt = (jwt) => ({ jwt, type: APP_ACTIONS.UPDATE_JWT });

export const updateAuthCode = (authCode) => ({ authCode, type: APP_ACTIONS.UPDATE_AUTH_CODE });

export const updateIsAuth = (isAuth) => ({ isAuth, type: APP_ACTIONS.UPDATE_IS_AUTH });

export const updateIsEditing = (isEditing) => ({ isEditing, type: APP_ACTIONS.UPDATE_IS_EDITING });

export const updateChannel = (channel) => ({ channel, type: APP_ACTIONS.UPDATE_CHANNEL });

export const updateFormStarted = (formStarted) => ({ formStarted, type: APP_ACTIONS.UPDATE_FORM_STARTED });

export const updateSelectedPropertyReference = (selectedPropertyReference) => ({
  selectedPropertyReference,
  type: APP_ACTIONS.UPDATE_FORM_STARTED,
});

export const updateLoadingMessage = (loadingMessage) => ({ type: APP_ACTIONS.UPDATE_LOADING_MESSAGE, loadingMessage });

export const resetApp = () => ({ type: APP_ACTIONS.RESET_APP });
