import React from 'react';
import { ContentGroup } from 'yw-ui.v2';
import PropTypes from 'prop-types';
import { PageHeading } from '../';

const PageContentHeader = ({ breadcrumb, title }) => {
  return (
    <ContentGroup>
      {breadcrumb ? breadcrumb : ''}
      <PageHeading title={title} />
      <div className="c-divider u-mb--400" />
    </ContentGroup>
  );
};

PageContentHeader.propTypes = {
  breadcrumb: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default PageContentHeader;
