import React from 'react';
import PropTypes from 'prop-types';
import { ContentGroup, Icon, Button } from 'yw-ui.v2';
import { getLongDateFormat } from '../../../../common/functions';
import billItemIcon from './billItemIcon.svg';

const BillItem = ({ date, icon, title, onClick }) => (
  <ContentGroup id="billItemWrapper" qa="bill-item-wrapper" className="bill-item-wrapper">
    {icon && <Icon qa="bills-payments-icon-document" colour="ywDarkBlue" size="250" icon={icon} />}
    {!icon && <img src={billItemIcon} alt="document icon" />}
    <Button state="link" qa="bills-payments-icon-document-link" label={`${title}(PDF)`} onClick={onClick} />
    &nbsp; | {getLongDateFormat(date)}
  </ContentGroup>
);

BillItem.propTypes = {
  date: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};
export default BillItem;
