import { API_RESPONSE_STATUSES } from '../../common/constants/apiResponseStatuses';
import { SOMETHING_WENT_WRONG_ACTION } from '../../common/constants/somethingWentWrongAction';
import { handleApiResponses, handleErrorRedirect, handlePushToDataLayer } from '../../common/functions/helpers';
import { APP_URLS } from '../../common/settings';
import { submitAddPropertyRequest, validateCustomerReferenceNumberRequest } from './addPropertyService';

export const ADD_PROPERTY_ACTIONS = {
  UPDATE_LOADING: 'ADD_PROPERTY_ACTIONS.UPDATE_LOADING',
  UPDATE_FORM_STARTED: 'ADD_PROPERTY_ACTIONS.UPDATE_FORM_STARTED',
  UPDATE_FORM_COMPLETED: 'ADD_PROPERTY_ACTIONS.UPDATE_FORM_COMPLETED',
  UPDATE_FORM_IS_EDITING: 'ADD_PROPERTY_ACTIONS.UPDATE_FORM_IS_EDITING',
  UPDATE_ADD_PROPERTY_FORM_DATA: 'ADD_PROPERTY_ACTIONS.UPDATE_ADD_PROPERTY_FORM_DATA',
  UPDATE_FORM_SUBMITTED_RESPONSE: 'ADD_PROPERTY_ACTIONS.UPDATE_FORM_SUBMITTED_RESPONSE',
  UPDATE_VALIDATE_REFERENCE_RESPONSE: 'ADD_PROPERTY_ACTIONS.UPDATE_VALIDATE_REFERENCE_RESPONSE',
  UPDATE_FORM_SUBMITTED_ERROR: 'ADD_PROPERTY_ACTIONS.UPDATE_FORM_SUBMITTED_ERROR',
  RESET_FORM: 'ADD_PROPERTY_ACTIONS.RESET_FORM',
};

export const updateAddPropertyIsLoading = (state) => ({
  type: ADD_PROPERTY_ACTIONS.UPDATE_LOADING,
  payload: state,
});

export const updateAddPropertyFormStarted = (state) => ({
  type: ADD_PROPERTY_ACTIONS.UPDATE_FORM_STARTED,
  payload: state,
});

export const updateAddPropertyFormCompleted = (state) => ({
  type: ADD_PROPERTY_ACTIONS.UPDATE_FORM_COMPLETED,
  payload: state,
});

export const updateAddPropertyFormIsEditing = (state) => ({
  type: ADD_PROPERTY_ACTIONS.UPDATE_FORM_IS_EDITING,
  payload: state,
});

export const updateAddPropertyFormData = (data) => ({
  type: ADD_PROPERTY_ACTIONS.UPDATE_ADD_PROPERTY_FORM_DATA,
  payload: data,
});

export const updateAddPropertyFormSubmittedResponse = (response) => ({
  type: ADD_PROPERTY_ACTIONS.UPDATE_FORM_SUBMITTED_RESPONSE,
  payload: response,
});

export const updateValidateReferenceResponse = (response) => ({
  type: ADD_PROPERTY_ACTIONS.UPDATE_VALIDATE_REFERENCE_RESPONSE,
  payload: response,
});

export const updateAddPropertyFormSubmittedError = (error) => ({
  type: ADD_PROPERTY_ACTIONS.UPDATE_FORM_SUBMITTED_ERROR,
  payload: error,
});

export const resetAddPropertyForm = () => ({
  type: ADD_PROPERTY_ACTIONS.RESET_FORM,
});

export const validateCustomerReferenceNumber = (params, token, history, successCallBack, errorCallBack) => async (
  dispatch,
) => {
  const successHandler = async (response) => {
    if (response.data) {
      if (response.data.valid) {
        dispatch(updateAddPropertyFormData(params));
      }
      dispatch(updateValidateReferenceResponse(response.data));
      successCallBack(response);
    }
  };

  const errorHandler = (error) => {
    handleErrorRedirect(error && error.status, SOMETHING_WENT_WRONG_ACTION.VALIDATE_PROPERTY, history, () => {
      dispatch(updateAddPropertyFormSubmittedError(error));
      errorCallBack();
    });
  };

  dispatch(updateAddPropertyFormSubmittedError(null));
  dispatch(updateAddPropertyIsLoading(true));

  try {
    const response = await validateCustomerReferenceNumberRequest(
      params.customerReferenceNumber.replace(/ /g, ''),
      token,
    );
    response ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    error.status ? errorHandler(error.status) : errorHandler(500);
  } finally {
    dispatch(updateAddPropertyIsLoading(false));
  }
};

const submitDataLayer = (state) => {
  handlePushToDataLayer({
    event: 'form_submit',
    stepName: APP_URLS.ADD_PROPERTY_CHECK_DETAILS,
    state,
    other: {
      step: 3,
    },
  });
};

export const submitAddProperty = (params, token, history, errorCallBack) => async (dispatch) => {
  const successHandler = () => {
    // Reset Add property journey and redirect to confirmation page.
    dispatch(resetAddPropertyForm());
    submitDataLayer('success');
    history.push(APP_URLS.ADD_PROPERTY_CONFIRMATION);
  };

  const errorHandler = (error) => {
    dispatch(updateAddPropertyIsLoading(false));
    if (error && error.status === API_RESPONSE_STATUSES.BAD_REQUEST) {
      errorCallBack(error);
    }
    handleErrorRedirect(error && error.status, SOMETHING_WENT_WRONG_ACTION.ADD_PROPERTY, history, () => {
      dispatch(updateAddPropertyFormSubmittedError(error.status));
      submitDataLayer('failure');
    });
  };

  dispatch(updateAddPropertyIsLoading(true));

  try {
    const response = await submitAddPropertyRequest(params, token);
    handleApiResponses(response, successHandler, errorHandler);
  } catch (error) {
    error ? errorHandler(error) : errorHandler(500);
    dispatch(updateAddPropertyIsLoading(false));
  }
};
