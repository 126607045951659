import { APP_ACTIONS } from './appActions';

export const INITIAL_STATE = {
  encryptedReferenceNumber: null,
  isAuth: false,
  authCode: null,
  jwt: null,
  isEditing: false,
  channel: 'web',
  formStarted: false,
  selectedPropertyReference: '',
  loadingMessage: '',
};

export const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP_ACTIONS.UPDATE_ENCRYPTED_REFERENCE_NUMBER:
      return {
        ...state,
        encryptedReferenceNumber: action.encryptedReferenceNumber,
      };

    case APP_ACTIONS.UPDATE_IS_AUTH:
      return {
        ...state,
        isAuth: action.isAuth,
      };

    case APP_ACTIONS.UPDATE_AUTH_CODE:
      return {
        ...state,
        authCode: action.authCode,
      };

    case APP_ACTIONS.UPDATE_JWT:
      return {
        ...state,
        jwt: action.jwt,
      };

    case APP_ACTIONS.UPDATE_IS_EDITING:
      return {
        ...state,
        isEditing: action.isEditing,
      };

    case APP_ACTIONS.UPDATE_CHANNEL:
      return {
        ...state,
        channel: action.channel,
      };

    case APP_ACTIONS.UPDATE_FORM_STARTED:
      return {
        ...state,
        formStarted: action.formStarted,
      };

    case APP_ACTIONS.UPDATE_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: action.loadingMessage,
      };

    case APP_ACTIONS.RESET_APP:
      return {
        ...INITIAL_STATE,
      };

    case APP_ACTIONS.UPDATE_SELECTED_PROPERTY_REFERENCE:
      return {
        ...state,
        selectedPropertyReference: action.selectedPropertyReference,
      };

    default:
      return state;
  }
};
