import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, InfoCard } from 'yw-ui.v2';
import { getLongDateFormat } from '../../../common/functions';
import { LastPaymentTextMapper, PAYMENT_FREQUENCY } from '../../Home/settings';
import { APP_URLS, YW_URLS } from '../../../common/settings';

const HeroTiles = ({ selectedProperty }) => {
  const getBalanceTileProperties = () => {
    const result = {
      title: 'Your balance',
      amount: 0,
      isInCredit: false,
      balanceText: '',
      showBalanceTextWithLink: false,
      showMakeAPaymentButton: false,
    };

    if (selectedProperty?.accountReference) {
      result.amount = selectedProperty.balanceOutstanding ? Math.abs(Number(selectedProperty.balanceOutstanding)) : 0;

      if (Number(selectedProperty.balanceOutstanding) < 0) {
        result.isInCredit = true;
      }
      if (selectedProperty.isClosed) {
        result.title = 'Your balance';
        result.balanceText = `This account closed on the ${getLongDateFormat(selectedProperty.accountEndDate)}.`;
        result.showBalanceTextWithLink = false;
        if (Number(selectedProperty.balanceOutstanding) > 0) {
          result.showMakeAPaymentButton = true;
        }
      } else {
        if (selectedProperty.isMetered) {
          if (selectedProperty.isDirectDebit) {
            result.showMakeAPaymentButton = false;
            if (selectedProperty.paymentFrequency === PAYMENT_FREQUENCY.QUARTERLY) {
              result.title = 'Outstanding balance';
              result.showBalanceTextWithLink = true;
            } else {
              result.title = 'Your balance';
              result.showBalanceTextWithLink = true;
            }
          } else {
            if (selectedProperty.paymentFrequency === PAYMENT_FREQUENCY.QUARTERLY) {
              result.title = 'Outstanding balance';
              result.showBalanceTextWithLink = true;
              result.showMakeAPaymentButton = true;
            } else {
              result.title = 'Your balance';
              result.showBalanceTextWithLink = true;
              result.showMakeAPaymentButton = true;
            }
          }
        } else {
          if (selectedProperty.isDirectDebit) {
            result.title = 'Remaining balance';
            result.showBalanceTextWithLink = true;
          } else {
            result.title = 'Remaining balance';
            result.showBalanceTextWithLink = true;
            result.showMakeAPaymentButton = true;
          }
        }
      }
    }
    return result;
  };

  return (
    <Row>
      <Col sm={6} xs={12} className="u-mb--400-md">
        <InfoCard
          id="yourBalance"
          qa="your-balance"
          aboutBalanceLink={getBalanceTileProperties().showBalanceTextWithLink ? APP_URLS.YOUR_BALANCE : ''}
          accountClosedText={getBalanceTileProperties().balanceText}
          amount={getBalanceTileProperties().amount}
          isInCredit={getBalanceTileProperties().isInCredit}
          makeAPaymentLink={
            getBalanceTileProperties().showMakeAPaymentButton
              ? `${YW_URLS.MAKE_A_PAYMENT}?accountReference=${encodeURIComponent(selectedProperty.accountReference)}`
              : ''
          }
          title={getBalanceTileProperties().title}
        />
      </Col>
      <Col sm={6} xs={12}>
        <InfoCard
          id="lastPayment"
          qa="last-payment"
          amount={selectedProperty.lastPaymentValue ? Math.abs(Number(selectedProperty.lastPaymentValue)) : 0}
          paymentFrequency={
            !selectedProperty.isClosed
              ? LastPaymentTextMapper(selectedProperty.lastPaymentDate, selectedProperty.paymentMethod).paymentFrequency
              : ''
          }
          title="Last payment"
          lastPaymentDateText={
            !selectedProperty.lastPaymentDate
              ? 'No payments made on this account'
              : LastPaymentTextMapper(selectedProperty.lastPaymentDate, selectedProperty.paymentMethod).paymentDate
          }
        />
      </Col>
    </Row>
  );
};

HeroTiles.propTypes = {
  selectedProperty: PropTypes.object,
};

export default HeroTiles;
