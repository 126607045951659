import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentGroup, ReviewDetails, Heading, Loader, Message } from 'yw-ui.v2';
import { handleFocus } from '../../../../common/functions';
import { APP_URLS } from '../../../../common/settings';
import { history } from '../../../../store';
import { submitAddProperty, updateAddPropertyFormIsEditing } from '../../../../store/addProperty/addPropertyActions';
import {
  addPropertyErrorSelector,
  addPropertyFormDataSelector,
  addPropertyIsLoadingSelector,
} from '../../../../store/addProperty/addPropertySelector';
import { selectedPropertySelector } from '../../../../store/properties/propertiesSelectors';
import { authResponseSelector } from '../../../../store/auth/selectors';
import { ButtonControls, PageContentHeader } from '../../../components';
import { HeroLayout } from '../../../layouts';
import { AddPropertyBreadcrumbs } from '../../components';
import { displayDateFormat, formatRequestDate } from '../../../../common/functions/helpers';
import { usePageLoaded } from '../../../../common/hooks';

const AddPropertyCheckDetails = () => {
  const dispatch = useDispatch();
  const authResponse = useSelector(authResponseSelector);
  const selectedProperty = useSelector(selectedPropertySelector);
  const addPropertyIsLoading = useSelector(addPropertyIsLoadingSelector);
  const addPropertyError = useSelector(addPropertyErrorSelector);
  const { customerReferenceNumber, postcode, nameOnBill, dateOnBill } = useSelector(addPropertyFormDataSelector);
  const pageLoaded = usePageLoaded();
  const [addPropertyErrors, setAddPropertyErrors] = useState('');

  useEffect(() => {
    handleFocus('#page-heading_heading');
  }, []);

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateAddPropertyFormIsEditing(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLoaded]);

  const goBack = () => {
    history.goBack();
  };

  const onSubmit = () => {
    const token = authResponse && authResponse.access_token ? authResponse.access_token : '';
    if (!token) {
      return;
    }
    setAddPropertyErrors('');
    const params = {
      accountReference: customerReferenceNumber,
      postcode: postcode,
      nameOnBill: nameOnBill,
      billDate: formatRequestDate(dateOnBill),
    };
    dispatch(
      submitAddProperty(params, token, history, (error) => {
        setAddPropertyErrors(error);
      }),
    );
  };

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className="u-anim--fade-in add-property-page">
        <PageContentHeader
          title="Please check and submit"
          breadcrumb={
            <AddPropertyBreadcrumbs isPropertySelected={selectedProperty.accountReference.length > 0} step={4} />
          }
        />

        {addPropertyIsLoading && <Loader message="Adding your property..." />}

        <Heading priority={1} size="sm" title="Account information" />
        <ReviewDetails
          items={[
            { key: 'Customer reference number', value: customerReferenceNumber },
            { key: 'Postcode', value: postcode.toUpperCase() },
          ]}
          actions={[
            {
              label: 'Change',
              action: () => {
                dispatch(updateAddPropertyFormIsEditing(true));
                history.push(APP_URLS.ADD_PROPERTY_ACCOUNT_DETAILS);
              },
              ariaLabel: 'Change your customer reference number or postcode',
            },
          ]}
          qa="accountDetailsCheckDetails"
          id="accountDetailsCheckDetails"
          className="u-mb--400"
        />
        <Heading priority={2} size="sm" title="Bill information" />
        <ReviewDetails
          items={[
            { key: 'Name on the bill', value: nameOnBill },
            { key: 'Date on the bill', value: displayDateFormat(dateOnBill) },
          ]}
          actions={[
            {
              label: 'Change',
              action: () => {
                dispatch(updateAddPropertyFormIsEditing(true));
                history.push(APP_URLS.ADD_PROPERTY_BILL_INFORMATION);
              },
              ariaLabel: 'Change the name or date on the bill',
            },
          ]}
          qa="billInformationCheckDetails"
          id="billInformationCheckDetails"
          className="u-mb--400"
        />
        {addPropertyErrors && addPropertyErrors.status === 200 && (
          <Message
            title="Sorry. There was a technical problem adding your property."
            state="error"
            id="add-property-post-error"
            qa="add-property-post-error"
          >
            {addPropertyError.errors && addPropertyErrors.errors.description}
          </Message>
        )}
        {addPropertyErrors && addPropertyErrors.status === 400 && (
          <Message title="" state="error" id="add-property-post-error" qa="add-property-post-error">
            {addPropertyErrors.errors && addPropertyErrors.errors.description}
          </Message>
        )}
        {addPropertyErrors && addPropertyErrors === 500 && (
          <Message
            title="Sorry. There was a technical problem adding your property."
            state="error"
            id="add-property-post-error"
            qa="add-property-post-error"
          />
        )}
        <ButtonControls nextLabel="Add property" onBack={goBack} onNext={onSubmit} />
      </ContentGroup>
    </HeroLayout>
  );
};

export default AddPropertyCheckDetails;
