import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ContentGroup, Message, Heading, AccordionSingle } from 'yw-ui.v2';
import { handleFocus } from '../../../../common/functions';
import { APP_URLS, YW_URLS } from '../../../../common/settings';
import { history } from '../../../../store';
import {
  updateAddPropertyFormStarted,
  updateAddPropertyFormCompleted,
} from '../../../../store/addProperty/addPropertyActions';
import {
  closedPropertiesListSelector,
  selectedPropertySelector,
} from '../../../../store/properties/propertiesSelectors';
import { ButtonControls, PageContentHeader } from '../../../components';
import { AddPropertyBreadcrumbs } from '../../components';
import { HeroLayout } from '../../../layouts';
import { handlePushToDataLayer } from '../../../../common/functions/helpers';

const AddPropertyStart = () => {
  const dispatch = useDispatch();
  const selectedProperty = useSelector(selectedPropertySelector);
  const { properties: closedProperties } = useSelector(closedPropertiesListSelector);

  useEffect(() => {
    handleFocus('#page-heading_heading');
  }, []);

  const goNext = () => {
    dispatch(updateAddPropertyFormStarted(true));
    dispatch(updateAddPropertyFormCompleted(false));
    handlePushToDataLayer({
      event: 'form_start',
      stepName: APP_URLS.ADD_PROPERTY,
      state: 'success',
    });
    history.push(APP_URLS.ADD_PROPERTY_ACCOUNT_DETAILS);
  };

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup
        className={`u-anim--fade-in add-property-page ${!selectedProperty.accountReference ? 'no-breadcrumb' : ''}`}
      >
        <PageContentHeader
          title="Add a property"
          breadcrumb={
            selectedProperty.accountReference && (
              <AddPropertyBreadcrumbs isPropertySelected={selectedProperty.accountReference.length > 0} step={1} />
            )
          }
        />

        {!selectedProperty.accountReference && closedProperties && closedProperties.length <= 0 && (
          <Message id="addPropertyNoHistory" qa="addPropertyNoHistory" className="u-mt--300 u-mb--400">
            Your online account is not linked to a property, you need to add a property to get started.
          </Message>
        )}

        {!selectedProperty.accountReference && closedProperties && closedProperties.length > 0 && (
          <Message id="addPropertyWithHistory" qa="addPropertyWithHistory" className="u-mt--300 u-mb--400">
            Your online account is not linked to a property, you need to add a property to get started or you can view{' '}
            <NavLink to={APP_URLS.PREVIOUS_PROPERTIES}>previous properties</NavLink>.
          </Message>
        )}

        <Heading priority={2} size="sm" title="You will need:" />

        <ul className="u-mt--150 u-ml--0 u-mb--300">
          <li className="u-ml--200 u-mb--50">
            your customer reference number (this can be found on the top right of your bill or statement)
          </li>
          <li className="u-ml--200 u-mb--50">your name as it appears on the bill</li>
          <li className="u-ml--200 u-mb--50">the date displayed on your bill</li>
          <li className="u-ml--200 u-mb--50">the postcode displayed on your bill.</li>
        </ul>

        <AccordionSingle
          title="Don't have a bill or statement?"
          className="accordion-single-toggle"
          qa="customer-reference-number-accordion"
          id="customer-reference-number-accordion"
        >
          <ContentGroup className="u-bg--ywLightGrey u-p--200">
            <ContentGroup marginTop="100" marginBottom="200">
              <p>
                If you&apos;ve just moved, please wait for your new bill to arrive. If you&apos;ve been with us a while
                and don&apos;t have a bill handy, please{' '}
                <a href={YW_URLS.GET_IN_TOUCH} target="_blank" rel="noopener noreferrer">
                  get in touch
                </a>
                .
              </p>
            </ContentGroup>
          </ContentGroup>
        </AccordionSingle>

        <ButtonControls nextLabel="Start" renderBack={false} onNext={goNext} />
      </ContentGroup>
    </HeroLayout>
  );
};

export default AddPropertyStart;
