export const envUrl = (developmentUrl, prodUrl) => (process.env.NODE_ENV === 'production' ? prodUrl : developmentUrl);

export const APP_URLS = {
  ACCOUNT_DISABLED: '/account-disabled',
  ACCOUNT_SETUP_IN_PROGRESS: '/account-setup-in-progress',
  ADD_PROPERTY: '/add-property',
  ADD_PROPERTY_ACCOUNT_DETAILS: '/add-property/account-details',
  ADD_PROPERTY_BILL_INFORMATION: '/add-property/bill-information',
  ADD_PROPERTY_CHECK_DETAILS: '/add-property/check-details',
  ADD_PROPERTY_CONFIRMATION: '/add-property/confirmation',
  AUTH_CALLBACK: '/auth/callback/',
  BILL_PREFERENCES: '/bill-preferences',
  BILLS_AND_PAYMENTS: '/bills-and-payments',
  CALLBACK: '/callback',
  CALLBACK_RESPONSE: '/callback/response',
  CHANGE_EMAIL: '/change-email',
  CONTACT_INFORMATION: '/contact-information',
  ERROR: '/error',
  MY_PROFILE: '/my-profile',
  PROPERTIES: '/properties',
  PREVIOUS_PROPERTIES: '/previous-properties',
  REDIRECT: '/redirect',
  RESPONSE: '/response',
  ROOT: '/',
  SOMETHING_WENT_WRONG: '/something-went-wrong',
  YOUR_BALANCE: '/your-balance',
};

export const ENDPOINTS = {
  PROPERTIES: 'Properties',
  TOKEN: 'connect/token',
};

export const AUTH_URLS = {
  BASE: envUrl('http://localhost:3000/', '#{BaseUrl}'),
  IDS: envUrl('https://logindev.yorkswater.com', '#{AuthBaseUrl}'),
};

export const YW_ENV_DOMAIN_NAMES = {
  LOCAL: 'localhost',
  DEV: 'mydev.yorkswater.com',
  SYS: 'mysys.yorkswater.com',
  QA: 'myqa.yorkswater.com',
  PROD: 'my.yorkshirewater.com',
};

export const isLoggedInMoveWithinVisible = [
  YW_ENV_DOMAIN_NAMES.LOCAL,
  YW_ENV_DOMAIN_NAMES.DEV,
  YW_ENV_DOMAIN_NAMES.SYS,
].includes(window.location.hostname)
  ? true
  : false;

export const YW_URLS = {
  ACCOUNT_AREA: envUrl(
    'https://mydev.yorkswater.com/SecureArea/Default.aspx',
    '#{WebsiteBaseUrl}/SecureArea/Default.aspx',
  ),
  CALLBACK: 'https://synthetix-ec1.com/clients/yorkshirewater/callback/?trigger=YWOnlineAccount',
  CALLBACK_FORM: 'https://www.yorkshirewater.com/contactus#callback',
  CHANGE_PASSWORD: `${AUTH_URLS.IDS}/manage/changePassword?returnUrl=${AUTH_URLS.BASE}${APP_URLS.MY_PROFILE.replace(
    /\//g,
    '',
  )}`,
  CLOSE_ACCOUNT: 'https://yorkshirewater.com/moving/closing-account/',
  CODES_OF_PRACTICE: 'https://www.yorkshirewater.com/policies',
  CONTACT_US: 'https://www.yorkshirewater.com/contactus',
  DIRECT_DEBIT: 'https://www.yorkshirewater.com/billing-payments/direct-debits/',
  FAQ: 'https://yorkshirewater.com/moving/frequently-asked-questions/',
  GET_IN_TOUCH: 'https://www.yorkshirewater.com/get-in-touch',
  HOME: 'https://www.yorkshirewater.com/',
  LOGIN: 'https://mydev.yorkswater.com/Login.aspx',
  MOVING_HOME: 'https://www.yorkshirewater.com/moving/',
  MOVE_IN: 'https://my.yorkshirewater.com/moving/move-in/',
  MOVE_OUT: 'https://my.yorkshirewater.com/moving/move-out/',
  MOVING_HOME_STUB: 'https://my.yorkshirewater.com/moving-home/',
  PAY_YOUR_BILL: 'https://www.yorkshirewater.com/billing-payments/paying-your-bill/',
  PRIVACY_TERMS: 'https://www.yorkshirewater.com/legal/#privacy-notice',
  REPORT_A_PROBLEM: 'https://www.yorkshirewater.com/your-water/report-a-problem/',
  SUPPLY_AREA: 'https://yorkshirewater.com/moving/are-you-in-our-supply-area/',
  TERMS: 'https://www.yorkshirewater.com/legal',
  REQUEST_COPY_OF_BILL: 'https://www.yorkshirewater.com/bill-account/request-a-copy-of-your-bill/',
  MOVE_WITHIN: envUrl('https://mydev.yorkswater.com/moving/move-within/', '#{WebsiteBaseUrl}/moving/move-within/'),
  MAKE_A_PAYMENT: envUrl(
    'https://mydev.yorkswater.com/make-a-payment/logged-in/redirect',
    '#{WebsiteBaseUrl}/make-a-payment/logged-in/redirect',
  ),
  SUBMIT_A_METER_READING: envUrl(
    'https://mydev.yorkswater.com/submit-meter-reading/logged-in/redirect',
    '#{WebsiteBaseUrl}/submit-meter-reading/logged-in/redirect',
  ),
  PAYMENT_PLAN: envUrl(
    'https://mydev.yorkswater.com/payments/change/logged-in/redirect',
    '#{WebsiteBaseUrl}/payments/change/logged-in/redirect',
  ),
  REGISTER_AN_ACCOUNT: 'https://my.yorkshirewater.com/register/start',
  WATER_METERS: 'https://www.yorkshirewater.com/bill-account/water-meters/',
};

export const getMovingHomeURL = (movingHomeJourney, accountReference) => {
  switch (window.location.hostname) {
    case YW_ENV_DOMAIN_NAMES.DEV: {
      return `https://${
        YW_ENV_DOMAIN_NAMES.DEV
      }/moving/${movingHomeJourney}/logged-in/redirect?accountReference=${encodeURIComponent(accountReference)}`;
    }
    case YW_ENV_DOMAIN_NAMES.SYS: {
      return `https://${
        YW_ENV_DOMAIN_NAMES.SYS
      }/moving/${movingHomeJourney}/logged-in/redirect?accountReference=${encodeURIComponent(accountReference)}`;
    }
    case YW_ENV_DOMAIN_NAMES.QA: {
      return `https://${
        YW_ENV_DOMAIN_NAMES.QA
      }/moving/${movingHomeJourney}/logged-in/redirect?accountReference=${encodeURIComponent(accountReference)}`;
    }
    case YW_ENV_DOMAIN_NAMES.PROD: {
      return `https://${
        YW_ENV_DOMAIN_NAMES.PROD
      }/moving/${movingHomeJourney}/logged-in/redirect?accountReference=${encodeURIComponent(accountReference)}`;
    }
    default:
      return `https://${
        YW_ENV_DOMAIN_NAMES.PROD
      }/moving/${movingHomeJourney}/logged-in/redirect?accountReference=${encodeURIComponent(accountReference)}`;
  }
};
