import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ContentGroup } from 'yw-ui.v2';
import { PageContentHeader } from '../../../components';
import { HeroLayout } from '../../../layouts';
import { AddPropertyBreadcrumbs } from '../../components';
import { selectedPropertySelector } from '../../../../store/properties/propertiesSelectors';
import { handlePushToDataLayer } from '../../../../common/functions/helpers';
import { APP_URLS } from '../../../../common/settings';

const AddPropertyConfirmation = () => {
  const selectedProperty = useSelector(selectedPropertySelector);

  useEffect(() => {
    handlePushToDataLayer({
      event: 'form_step4',
      stepName: APP_URLS.ADD_PROPERTY_CONFIRMATION,
      state: 'success',
      other: {
        step: 4,
      },
    });
  }, []);

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className="u-anim--fade-in add-property-confirmation-page">
        <PageContentHeader
          title="Thank you for adding a property"
          breadcrumb={
            <AddPropertyBreadcrumbs isPropertySelected={selectedProperty.accountReference.length > 0} step={5} />
          }
        />
        <ContentGroup marginTop="300">
          <p id="page_copy">You&apos;ll see your property in your Online Account shortly.</p>
        </ContentGroup>
      </ContentGroup>
    </HeroLayout>
  );
};

export default AddPropertyConfirmation;
