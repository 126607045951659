export const setBlur = (el) => {
  el.addEventListener('blur', () => {
    el.removeAttribute('tabindex');
  });
};

export const setFocus = (el) => {
  if (el.hasAttribute('tabindex')) {
    el.focus();
  } else {
    el.setAttribute('tabindex', '-1');
    el.focus();
    setBlur(el);
  }
};

// Either pass in a valid CSS selector, or a React Ref
export const handleFocus = (el) => {
  if (document.querySelector(el)) setFocus(document.querySelector(el));
  if (el.current) setFocus(el.current);
};
