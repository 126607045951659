import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { ModalDialog } from 'yw-ui.v2';

const InactivityTimer = ({
  title,
  promptTime,
  timeBeforePrompt,
  onIdle,
  onActive,
  onPrompt,
  ignoreTimer,
  cancelBtn,
  confirmBtn,
  children,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    reset();
  };

  const handleOnPrompt = () => {
    if (ignoreTimer) {
      return;
    }
    if (onPrompt) {
      onPrompt();
    }
    openModal();
  };

  const handleOnIdle = () => {
    closeModal();
    if (!ignoreTimer) {
      message({ action: 'idle' }, true);
    }
  };

  const handleOnConfirm = () => {
    message({ action: 'active' }, true);
  };

  const handleOnActive = (event) => {
    closeModal();
    if (onActive) {
      onActive(event);
    }
  };

  const handleOnMessage = (data) => {
    switch (data.action) {
      case 'idle': {
        onIdle();
        return;
      }
      case 'active': {
        closeModal();
        return;
      }
      default:
        return;
    }
  };

  const { reset, message } = useIdleTimer({
    timeout: promptTime,
    promptBeforeIdle: timeBeforePrompt,
    crossTab: true,
    onPrompt: handleOnPrompt,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onMessage: handleOnMessage,
  });

  return (
    <ModalDialog
      title={title}
      qa="inactivity-timer-modal"
      isOpen={modalOpen}
      cancelButton={{ label: cancelBtn ? cancelBtn : 'Sign out', state: 'default-outline', action: handleOnIdle }}
      confirmButton={{ label: confirmBtn ? confirmBtn : 'Stay signed in', state: 'success', action: handleOnConfirm }}
    >
      {children}
    </ModalDialog>
  );
};

InactivityTimer.propTypes = {
  cancelBtn: PropTypes.string,
  children: PropTypes.node.isRequired,
  confirmBtn: PropTypes.string,
  ignoreTimer: PropTypes.bool.isRequired,
  onActive: PropTypes.func,
  onIdle: PropTypes.func.isRequired,
  onPrompt: PropTypes.func,
  promptTime: PropTypes.number.isRequired,
  timeBeforePrompt: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default InactivityTimer;
