import RestClient from '../../common/modules/rest';
import { API } from '../../common/settings';
import { createToken } from '../../common/functions';

const rest = new RestClient();
const propertiesEndPoint = API.API_ENDPOINTS.PROPERTIES;
const propertyEndPoint = API.API_ENDPOINTS.PROPERTY;
const switchToPaperlessEndpoint = API.API_ENDPOINTS.SWITCH_PAPERLESS;
const pendedPropertiesEndpoint = API.API_ENDPOINTS.PENDED_ACCOUNT_SETUP;

export const fetchProperties = (page, pageSize, token, closed = false) =>
  closed
    ? rest.get(`${propertiesEndPoint}?page=${page}&pageSize=${pageSize}&closed=true`, createToken(token))
    : rest.get(`${propertiesEndPoint}?page=${page}&pageSize=${pageSize}`, createToken(token));

export const fetchProperty = (accountReference, token) =>
  rest.get(`${propertyEndPoint}?accountReference=${accountReference}`, createToken(token));

export const switchToPaperless = (params, token) => rest.post(switchToPaperlessEndpoint, params, createToken(token));

export const postProperty = (params, token) => rest.post(propertiesEndPoint, params, createToken(token));

export const fetchPendedProperties = (token) => rest.get(pendedPropertiesEndpoint, createToken(token));
