import { BILL_PREFERENCE } from '..';

export const customValidators = {
  noSelectionValidation: (value, options) => {
    const { isBillingPaperless, isEligibleForPaperlessBilling } = options;
    if (value === BILL_PREFERENCE.POST && !isBillingPaperless) {
      return options.message.postError;
    } else if (value === BILL_PREFERENCE.EMAIL && isBillingPaperless && isEligibleForPaperlessBilling) {
      return options.message.emailError;
    }
  },
  notEligibleValidator: (value, options) => {
    const { isEligibleForPaperlessBilling } = options;
    if (value === BILL_PREFERENCE.EMAIL && !isEligibleForPaperlessBilling) {
      return options.message;
    }
  },
};
