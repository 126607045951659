import React from 'react';
import { ContentGroup, Breadcrumbs, Heading, Message } from 'yw-ui.v2';
import { PageContentHeader } from '../components';
import { useSelector } from 'react-redux';
import { selectedPropertySelector } from '../../store/properties/propertiesSelectors';
import { HeroLayout } from '../layouts';

const YourBalance = () => {
  const selectedProperty = useSelector(selectedPropertySelector);

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className="u-anim--fade-in">
        <PageContentHeader
          title="About your balance"
          breadcrumb={
            <Breadcrumbs showIcon={true} crumbs={[{ label: 'Home', to: '/' }, { label: 'About your balance' }]} />
          }
        />
        {selectedProperty && selectedProperty.isMetered ? (
          <ContentGroup className="u-mb--400" qa="balance-notice-message" id="balanceNoticeMessage">
            <p>
              Your balance will usually be in credit or debit. In most cases, you&apos;ll be building up credit to pay
              your next bill.
            </p>
            <Heading className="u-mt--200 u-mb--200" title="Here's an example of a payment plan:" size="sm" />
            <ul className="u-mb--200">
              <li>
                In month one, you pay £30 and your <b>balance is now £30 in credit. </b>
              </li>
              <li>
                In month two, you pay £30 and your <b>balance is now £60 in credit. </b>
              </li>
              <li>
                In month three, you pay £30 and your <b>balance is now £90 in credit. </b>
              </li>
              <li>
                In month three, you&apos;re also billed £100 and your <b>balance is now £10 in debit. </b>
              </li>
            </ul>
            <p>
              Your water use can vary, so it&apos;s unlikely you&apos;ll have a £0 balance after you&apos;re billed.
              It&apos;s normal for your balance to move in and out of debit and credit.{' '}
            </p>
            <p>
              We look at your water use to make sure you&apos;re not paying too much or too little. We normally review
              your payments every 12 months, but if we see a big change in your water use, we may amend your payments
              sooner.{' '}
            </p>
          </ContentGroup>
        ) : (
          <>
            <Message
              title="Please note"
              qa="unmetered-notice-message"
              id="unmeteredNoticeMessage"
              className="u-mt--300 u-mb--300"
            >
              <p>Your remaining balance is what you have left to pay.</p>
            </Message>
            <Heading title="For example:" size="sm" />
            <p>
              If you&apos;re billed £500 for the year, we will usually split this into monthly payments starting from
              April that you pay over 8, 10 or 12 months.
            </p>
          </>
        )}
      </ContentGroup>
    </HeroLayout>
  );
};

export default YourBalance;
