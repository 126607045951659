import React from 'react';
import { PropTypes } from 'prop-types';
import { Header, Footer } from 'yw-ui.v2';
import { APPLICATION_NAME, MAIN_URL } from '../../common/settings';
import { NavBarItems } from '../components';

function MainLayout({ children }) {
  return (
    <div className="c-app-online-account">
      <Header
        id="main-header"
        title={APPLICATION_NAME}
        toLink={MAIN_URL}
        logoLink="https://www.yorkshirewater.com/"
        skipToMainContent
        headerItems={<NavBarItems />}
      />
      {children}
      <Footer id="main-footer" />
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
