import React from 'react';
import PropTypes from 'prop-types';
import { Message, ScrollTo, ContentGroup } from 'yw-ui.v2';

const genericError = (error) => ({
  id: error,
  title: 'Sorry. There was a technical problem finding your account information.',
  message: <p>Please try again later.</p>,
});

export const ERRORS = {
  inValidCustomerReferenceNumber: {
    id: 'inValid-customer-reference-number',
    title: 'Sorry. There was a technical problem validating your customer reference number.',
    message: (
      <p>
        Please enter a valid customer reference number. Your customer reference number can be found in the top right of
        your bill or statement.
      </p>
    ),
  },
  unknownError: genericError('unknown'),
  400: genericError('400'),
  401: genericError('401'),
  403: genericError('403'),
  429: genericError('429'),
  500: genericError('500'),
  503: genericError('503'),
};

const ErrorMessage = ({ error, errorCount }) => (
  <div>
    {ERRORS[error] && (
      <ScrollTo scrollCount={errorCount}>
        <ContentGroup className="u-anim--flash">
          <Message
            title={ERRORS[error].title}
            state="error"
            id={`${ERRORS[error].id}-error-message`}
            qa={`${ERRORS[error].id}-error-message`}
          >
            {ERRORS[error].message}
          </Message>
        </ContentGroup>
      </ScrollTo>
    )}
  </div>
);

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  errorCount: PropTypes.number.isRequired,
};

export default ErrorMessage;
