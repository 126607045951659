import { PROPERTIES_ACTIONS } from './propertiesActions';

export const INITIAL_STATE = {
  loading: false,
  propertiesList: {
    pageNumber: 0,
    properties: [],
    totalPages: 0,
    totalProperties: 0,
    accountDisabled: false,
  },
  closedPropertiesList: {
    pageNumber: 0,
    properties: [],
    totalPages: 0,
    totalProperties: 0,
    accountDisabled: false,
  },
  pendedProperty: {
    hasPendedAutoEnrol: false,
    occupationDate: null,
  },
  selectedProperty: {
    accountReference: '',
    displayAccountReference: '',
    accountStatus: '',
    address: {
      houseName: '',
      houseNumber: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressLine4: '',
      addressLine5: '',
      postcode: '',
    },
    firstName: '',
    email: '',
    accountStartDate: '',
    accountEndDate: '',
    balanceOutstanding: 0,
    lastPaymentValue: 0,
    lastPaymentDate: '',
    paymentMethod: '',
    paymentFrequency: '',
    isClosed: false,
    isMetered: true,
    isPaymentPlan: true,
    isReadyForReading: false,
    isDirectDebit: true,
    isBillingPaperless: true,
    isEligibleForPaperlessBilling: true,
  },
  error: null,
};

export const propertiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROPERTIES_ACTIONS.UPDATE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case PROPERTIES_ACTIONS.UPDATE_PROPERTIES_LIST: {
      return {
        ...state,
        propertiesList: action.payload,
      };
    }
    case PROPERTIES_ACTIONS.UPDATE_CLOSED_PROPERTIES_LIST: {
      return {
        ...state,
        closedPropertiesList: action.payload,
      };
    }
    case PROPERTIES_ACTIONS.UPDATE_SELECTED_PROPERTY: {
      return {
        ...state,
        selectedProperty: action.payload,
      };
    }
    case PROPERTIES_ACTIONS.UPDATE_BILL_PREFERENCE: {
      return {
        ...state,
        selectedProperty: {
          ...state.selectedProperty,
          isBillingPaperless: action.payload,
        },
      };
    }
    case PROPERTIES_ACTIONS.UPDATE_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case PROPERTIES_ACTIONS.RESET_PROPERTIES: {
      return {
        ...INITIAL_STATE,
      };
    }
    case PROPERTIES_ACTIONS.PENDED_PROPERTIES: {
      return {
        ...state,
        pendedProperty: action.payload,
      };
    }
    default:
      return state;
  }
};
