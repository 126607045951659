import React from 'react';
import { ContentGroup, Button } from 'yw-ui.v2';
import { useSelector } from 'react-redux';
import { useAppAuth } from '../../common/hooks';
import { PageContentHeader } from '../components';
import { HeroLayout } from '../layouts';
import { pendedPropertySelector } from '../../store/properties/propertiesSelectors';

const AccountSetupInProgress = () => {
  const doLogOut = useAppAuth();
  const pendedProperty = useSelector(pendedPropertySelector);
  const showDateToVisitBackOn =
    pendedProperty &&
    pendedProperty?.hasPendedAutoEnrol &&
    pendedProperty?.occupationDate != null &&
    pendedProperty?.occupationDate < Date.UTC;
  const MillisecondsInADay = 86400000;
  const enrolDate = new Date(new Date(pendedProperty?.occupationDate).getTime() + MillisecondsInADay);
  const enrolDateString = `Please check back on ${enrolDate?.getDate()}/${enrolDate?.getMonth() +
    1}/${enrolDate?.getFullYear()}`;

  const onLogout = () => {
    doLogOut();
  };

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className="u-anim--fade-in account-disabled">
        <PageContentHeader title="We're setting up your account" breadcrumb="" className="u-mt--300" />
        <p>You will be able to log in to your online account soon.</p>
        <p>{showDateToVisitBackOn ? enrolDateString : 'Please check back later.'}</p>
        <Button
          className="u-mt--300"
          type="button"
          label="Log out"
          state="default-outline"
          onClick={onLogout}
          id="log-out-button"
          qa="log-out-button"
        />
      </ContentGroup>
    </HeroLayout>
  );
};

export default AccountSetupInProgress;
