import RestClient from '../../common/modules/rest';
import { API } from '../../common/settings';
import { createToken } from '../../common/functions';

const rest = new RestClient();
const customerDetailEndPoint = API.API_ENDPOINTS.CUSTOMER_DETAIL;
const customerPreferencesEndPoint = API.API_ENDPOINTS.CUSTOMER_PREFERENCES;

export const fetchCustomerDetails = (token) => rest.get(customerDetailEndPoint, createToken(token));

export const updateCustomerPreferences = (params, token) =>
  rest.post(customerPreferencesEndPoint, params, createToken(token));
