import { SOMETHING_WENT_WRONG_ACTION } from '../../common/constants/somethingWentWrongAction';
import { handleApiResponses, handleErrorRedirect } from '../../common/functions/helpers';
import { RADIO_BOOL } from './customerReducer';
import { fetchCustomerDetails, updateCustomerPreferences } from './customerService';

export const CUSTOMER_ACTIONS = {
  UPDATE_LOADING: 'CUSTOMER_ACTIONS.UPDATE_LOADING',
  UPDATE_CUSTOMER_RESPONSE: 'CUSTOMER_ACTIONS.UPDATE_CUSTOMER_RESPONSE',
  UPDATE_CUSTOMER_ERROR: 'CUSTOMER_ACTIONS.UPDATE_CUSTOMER_ERROR',
  RESET: 'CUSTOMER_ACTIONS.RESET',
};

export const updateCustomerIsLoading = (state) => ({
  type: CUSTOMER_ACTIONS.UPDATE_LOADING,
  payload: state,
});

export const updateCustomerResponse = (response) => ({
  type: CUSTOMER_ACTIONS.UPDATE_CUSTOMER_RESPONSE,
  payload: response,
});

export const updateCustomerError = (error) => ({
  type: CUSTOMER_ACTIONS.UPDATE_CUSTOMER_ERROR,
  payload: error,
});

export const resetCustomer = () => ({
  type: CUSTOMER_ACTIONS.RESET,
});

export const getCustomerDetails = (token, history) => async (dispatch) => {
  const successHandler = (response) => {
    if (response?.data) {
      dispatch(
        updateCustomerResponse({
          ...response.data,
          serviceFeedback: response.data.serviceFeedback ? RADIO_BOOL.YES : RADIO_BOOL.NO,
          supplyIssues: response.data.supplyIssues ? RADIO_BOOL.YES : RADIO_BOOL.NO,
        }),
      );
    }
  };

  const errorHandler = (error) => {
    handleErrorRedirect(error, SOMETHING_WENT_WRONG_ACTION.GET_CUSTOMER, history, () => {
      dispatch(updateCustomerError(error));
    });
  };

  dispatch(updateCustomerIsLoading(true));

  try {
    const response = await fetchCustomerDetails(token);
    response.status === 200 ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    error.state ? errorHandler(error.status) : errorHandler(500);
  } finally {
    dispatch(updateCustomerIsLoading(false));
  }
};

export const submitCustomerPreferences = (
  { serviceFeedback, supplyIssues },
  token,
  history,
  responseCallback,
  errorCallback,
) => async (dispatch) => {
  const successHandler = (response) => responseCallback(response);

  const errorHandler = (error) => {
    errorCallback();
    handleErrorRedirect(error, SOMETHING_WENT_WRONG_ACTION.CUSTOMER_PREFERENCES, history, () => {
      dispatch(updateCustomerError(error));
    });
  };

  dispatch(updateCustomerIsLoading(true));

  try {
    const response = await updateCustomerPreferences({ serviceFeedback, supplyIssues }, token);
    handleApiResponses(response, successHandler, errorHandler);
  } catch (error) {
    error.state ? errorHandler(error.status) : errorHandler(500);
  } finally {
    dispatch(updateCustomerIsLoading(false));
  }
};
