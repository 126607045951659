export const authResponseSelector = (state) => state.auth.authResponse;

export const authErrorSelector = (state) => state.auth.error;

export const authIsLoadingSelector = (state) => state.auth.isLoading;

export const isAuthenticatedSelector = (state) => state.auth.isAuthenticated;

export const tokenSelector = (state) => state.auth.authResponse?.access_token;

export const authHasErrorSelector = (state) => state.auth.hasError;

export const authIsExpiringSelector = (state) => state.auth.isExpiring;
