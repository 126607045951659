import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter as Router } from 'connected-react-router';
import { AuthProvider } from 'fluid-auth';
import { Loader } from 'yw-ui.v2';
import App from './App';
import { history } from './store';
import { store, persistor } from './store';
import { APP_BASENAME, authOptions } from './common/settings';

import 'yw-ui.v2/dist/css/yw-ui.v2.min.css';

// const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router basename={APP_BASENAME} history={history}>
        <AuthProvider
          store={store}
          history={history}
          {...authOptions}
          ignoreRoles
          loadingRenderer={() => <Loader message="Loading online account..." />}
        >
          <App />
        </AuthProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
