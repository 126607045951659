import moment from 'moment';
import { PAYMENT_FREQUENCY } from '../../scenes/Home/settings';
import { API_RESPONSE_STATUSES } from '../constants/apiResponseStatuses';
import { APP_URLS } from '../settings';

export const createToken = (token) => ({ Authorization: `Bearer ${token}` });

export const saveAccountReference = (accountReference) => {
  const data = {
    accountReference: encodeURIComponent(accountReference),
    lastUpdated: new Date(),
  };
  sessionStorage.setItem('savedAccountReference', JSON.stringify(data));
};

export const getSavedAccountReference = () => {
  const savedAccountReference = sessionStorage.getItem('savedAccountReference') || null;
  let result = { accountReference: '', lastUpdated: '' };
  if (savedAccountReference) {
    const { accountReference, lastUpdated } = JSON.parse(savedAccountReference);
    result = {
      accountReference: accountReference,
      lastUpdated: new Date(lastUpdated),
    };
  }
  return result;
};

export const getFormatAddressDetails = (addressSource) => {
  if (!addressSource) {
    return '';
  }
  const { houseName, houseNumber, addressLine1, addressLine2, addressLine3, postcode } = addressSource;
  let address = '';

  if (houseName) {
    address += `${houseName}, `;
  }
  if (houseNumber) {
    address += `${houseNumber} `;
  }
  if (addressLine1) {
    address += `${addressLine1}, `;
  }
  if (addressLine2) {
    address += `${addressLine2}, `;
  }
  if (addressLine3) {
    address += `${addressLine3}, `;
  }
  // if (addressLine4) {
  //   address += `${addressLine4}, `;
  // }
  // if (addressLine5) {
  //   address += `${addressLine5}, `;
  // }
  if (postcode) {
    address += postcode;
  }
  return address;
};

export const getFormattedAccountReference = (referenceNumber) => {
  let formattedReferenceNumber = '';

  if (referenceNumber) {
    formattedReferenceNumber = `${referenceNumber.substr(0, 5)}${' '}${referenceNumber.substr(
      5,
      3,
    )}${' '}${referenceNumber.substr(8, 3)}${' '}${referenceNumber.substr(11, 4)}${' '}${referenceNumber.substr(15)}`;
  }

  return formattedReferenceNumber;
};

export const getLongDateFormat = (date) => {
  if (!date) {
    return '';
  }
  const monthsText = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const dateEntered = new Date(date);
  const day = dateEntered.getDate();
  const month = dateEntered.getMonth();
  const year = dateEntered.getFullYear();
  return `${day < 10 ? `0${day}` : day} ${monthsText[month]} ${year}`;
};

export const formatDate = (dateTime) => {
  const d = new Date(dateTime);
  dateTime = d.toLocaleString('en-GB');
  dateTime = dateTime.split(',')[0];
  return moment(dateTime, 'DD/MM/YYYY').format('YYYY-MM-DD');
};

export const displayDateFormat = (date) => {
  const d = new Date(date);
  date = d.toLocaleString('en-GB');
  date = date.split(',')[0];
  return moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
};

export const formatRequestDate = (date) => {
  if (!date) {
    return '';
  }

  const dateFOrmated = moment(new Date(date));
  // Add 1 hour to time if in Daylight Saving Time.
  if (dateFOrmated.isDST()) {
    dateFOrmated.add(1, 'h');
  }
  return dateFOrmated.toJSON();
};

export const getFormattedFrequencyText = (paymentFrequency) => {
  switch (paymentFrequency) {
    case PAYMENT_FREQUENCY.MONTHLY: {
      return 'monthly';
    }
    case PAYMENT_FREQUENCY.ANNUALLY: {
      return 'annually';
    }
    case PAYMENT_FREQUENCY.HALF_YEARLY: {
      return 'half yearly';
    }
    case PAYMENT_FREQUENCY.FORTNIGHTLY: {
      return 'fortnightly';
    }
    case PAYMENT_FREQUENCY.WEEKLY: {
      return 'weekly';
    }
    case PAYMENT_FREQUENCY.QUARTERLY: {
      return 'quarterly';
    }
    case PAYMENT_FREQUENCY.PER_BILL: {
      return 'per bill';
    }
    case PAYMENT_FREQUENCY.ONE_OFF: {
      return 'one off';
    }
    case PAYMENT_FREQUENCY.FOUR_WEEKLY: {
      return 'four weekly';
    }
    default:
      return '';
  }
};

export const downloadPDFDocument = (fileName, data) => {
  const file = new Blob([data], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(file);
  link.download = fileName;
  link.click();
};

export const handleApiResponses = (response, successHandler, errorHandler) => {
  if (response) {
    if (response.status === API_RESPONSE_STATUSES.OK) {
      if (successHandler) {
        successHandler(response);
      }
    } else if (response.data && response.data.description && response.data.error) {
      if (errorHandler) {
        errorHandler(response);
      }
    } else {
      if (errorHandler) {
        errorHandler(500);
      }
    }
  } else {
    if (errorHandler) {
      errorHandler(500);
    }
  }
};

export const handleErrorRedirect = (error, actionType, history, callBack) => {
  if ([API_RESPONSE_STATUSES.SERVER_ERROR].includes(error)) {
    history.push(`${APP_URLS.SOMETHING_WENT_WRONG}/${actionType}`);
  } else {
    if (callBack) {
      callBack();
    }
  }
};

export const stripLeadingSlashFromLink = (link) => {
  if (!link) {
    return '';
  }
  // Cast link to string;
  const linkString = `${link}`;
  return linkString.charAt(0) === '/' ? linkString.substring(1) : linkString;
};

export const handlePushToDataLayer = ({ event, stepName, state, other }) => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      ...(event && { event }), // The event
      form_id: 'online_account', // The form id
      ...(stepName && { form_name: stepName.replace('/', '').replaceAll('-', '_') }), // The name of the step
      ...(state && { form_state: state }), // The state success / failure
      ...other, // Optionally add more properties to the object
    });
  }
};
