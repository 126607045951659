import React from 'react';
import PropTypes from 'prop-types';
import { PrivateRoute } from 'fluid-auth';
import { APP_URLS } from '../../common/settings';
import { Redirect } from 'react-router-dom';

const AddPropertyFormRoute = ({ component: Component, formStarted, formCompleted, props, ...rest }) => {
  const getComponent = () => {
    return formStarted || (!formStarted && formCompleted) ? (
      <Component {...props} />
    ) : (
      <Redirect to={APP_URLS.ADD_PROPERTY} />
    );
  };
  return <PrivateRoute {...rest} path={''} render={() => getComponent()} component={getComponent()} exact />;
};

AddPropertyFormRoute.propTypes = {
  component: PropTypes.any,
  formCompleted: PropTypes.bool,
  formStarted: PropTypes.bool,
  props: PropTypes.any,
};

export default AddPropertyFormRoute;
