import { POSTCODE_OR_EMPTY_STRING_REGEX } from '../../../../../common/settings/regex';

export const constraints = {
  customerReferenceNumber: {
    presence: {
      allowEmpty: false,
      message: 'Please enter a Customer Reference Number (CRN) from a recent bill for an open Yorkshire Water account',
    },
    customerReferenceNumberValidator: {
      message: "This doesn't look like a customer reference number.",
    },
  },
  postcode: {
    presence: { allowEmpty: false, message: 'Please enter a Postcode from a recent bill matching the CRN entered' },
    format: {
      pattern: POSTCODE_OR_EMPTY_STRING_REGEX,
      message: "This doesn't look like a postcode.",
    },
  },
};
