import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentGroup, FluidForm, FormRow, FluidField, AccordionSingle, Loader, Row, Col } from 'yw-ui.v2';
import { history } from '../../../../store';
import { APP_URLS, YW_URLS } from '../../../../common/settings';
import { ButtonControls, PageContentHeader } from '../../../components';
import { HeroLayout } from '../../../layouts';
import { constraints, customValidators } from './validation';
import { tokenSelector } from '../../../../store/auth/selectors';
import {
  addPropertyFormDataSelector,
  addPropertyIsLoadingSelector,
  addPropertyErrorSelector,
  addPropertyValidateReferenceSelector,
  addPropertyFormIsEditingSelector,
} from '../../../../store/addProperty/addPropertySelector';
import { validateCustomerReferenceNumber } from '../../../../store/addProperty/addPropertyActions';
import { handleFocus } from '../../../../common/functions';
import { ErrorMessage } from '../../../components';
import bill_image from '../../../../common/images/bill_image.png';
import { selectedPropertySelector } from '../../../../store/properties/propertiesSelectors';
import { AddPropertyBreadcrumbs } from '../../components';
import { handlePushToDataLayer } from '../../../../common/functions/helpers';

const AddPropertyAccountDetails = () => {
  const dispatch = useDispatch();
  const selectedProperty = useSelector(selectedPropertySelector);
  const { customerReferenceNumber, postcode, nameOnBill, dateOnBill } = useSelector(addPropertyFormDataSelector);
  const token = useSelector(tokenSelector);
  const addPropertyIsLoading = useSelector(addPropertyIsLoadingSelector);
  const addPropertyError = useSelector(addPropertyErrorSelector);
  const addPropertyValidateReference = useSelector(addPropertyValidateReferenceSelector);
  const [errorCount, setErrorCount] = useState(0);
  const [inValidCustomerReferenceNumber, setInValidCustomerReferenceNumber] = useState(false);
  const isEditing = useSelector(addPropertyFormIsEditingSelector);

  useEffect(() => {
    handleFocus('#page-heading_heading');
    setErrorCount(errorCount + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPropertyError]);

  useEffect(() => {
    if (addPropertyValidateReference && !addPropertyValidateReference.valid) {
      handleFocus('#page-heading_heading');
      setInValidCustomerReferenceNumber(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPropertyValidateReference]);

  const goBack = () => {
    history.goBack();
  };

  const submitDataLayer = (state) => {
    handlePushToDataLayer({
      event: 'form_step1',
      stepName: APP_URLS.ADD_PROPERTY_ACCOUNT_DETAILS,
      state,
      other: {
        step: 1,
      },
    });
  };

  const onSubmit = async ({ postcode, customerReferenceNumber }) => {
    const params = { customerReferenceNumber, postcode, nameOnBill, dateOnBill };
    dispatch(
      validateCustomerReferenceNumber(
        params,
        token,
        history,
        (response) => {
          if (response.data && response.data.valid) {
            if (isEditing) {
              submitDataLayer('success');
              history.push(APP_URLS.ADD_PROPERTY_CHECK_DETAILS);
            } else {
              submitDataLayer('failure');
              history.push(APP_URLS.ADD_PROPERTY_BILL_INFORMATION);
            }
          }
        },
        () => submitDataLayer('failure'),
      ),
    );
  };

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className="u-anim--fade-in add-property-page">
        <PageContentHeader
          title="Add a property to your account"
          breadcrumb={
            <AddPropertyBreadcrumbs isPropertySelected={selectedProperty.accountReference.length > 0} step={2} />
          }
        />
        {addPropertyIsLoading && <Loader message="Loading please wait..." />}
        <p>If you&apos;re responsible for more than one property, you can add them to your online account.</p>

        <ContentGroup marginTop="300">
          <Row>
            <Col sm={6}>
              <FluidForm
                constraints={constraints}
                initialValues={{ customerReferenceNumber, postcode }}
                customValidators={customValidators}
                onSubmit={onSubmit}
                suppressHtmlValidation
              >
                <FormRow
                  className="u-mb--100"
                  label=""
                  id="customerDetails"
                  name="customerDetails"
                  qa="customerDetails"
                  valid={addPropertyError !== 404}
                  messages={[
                    addPropertyError === 404
                      ? 'Sorry. There was a problem with the Customer Reference Number or property postcode that you entered. Please check and try again.'
                      : null,
                  ]}
                  multi
                >
                  <FluidField
                    label="Customer Reference Number"
                    hint="The 15 or 16 digit number that appears on the top right of your bill or statement."
                    id="customerReferenceNumber"
                    name="customerReferenceNumber"
                    qa="customerReferenceNumber"
                    data-testid="customerReferenceNumber"
                    component="text"
                    condensed
                    isRequiredMarker
                  />

                  <AccordionSingle
                    title="Can't find your customer reference number?"
                    className="accordion-single-toggle"
                    qa="customer-reference-number-accordion"
                    id="customer-reference-number-accordion"
                  >
                    <ContentGroup className="u-bg--ywLightGrey u-p--200">
                      <ContentGroup marginTop="100" marginBottom="200">
                        <p>You can find your customer reference number on your latest bill or statement. </p>
                        <p>
                          If you can&apos;t find you customer reference number, please {` `}
                          <a href={YW_URLS.GET_IN_TOUCH} target="_blank" rel="noopener noreferrer">
                            get in touch
                          </a>
                          .
                        </p>
                      </ContentGroup>
                      <img
                        src={bill_image}
                        className="u-mb--100"
                        alt="Your customer reference number can be found in the top right of your bill or statement"
                      />
                    </ContentGroup>
                  </AccordionSingle>

                  <FluidField
                    label="Property postcode"
                    id="postcode"
                    name="postcode"
                    qa="postcode"
                    data-testid="postcode"
                    component="text"
                    isRequiredMarker
                    condensed
                  />
                </FormRow>

                {addPropertyError && <ErrorMessage error={addPropertyError} errorCount={errorCount} />}
                {inValidCustomerReferenceNumber && (
                  <ErrorMessage error="inValidCustomerReferenceNumber" errorCount={errorCount} />
                )}

                <ButtonControls onBack={goBack} nextLabel={isEditing ? 'Save' : 'Next'} renderBack={!isEditing} />
              </FluidForm>
            </Col>
          </Row>
        </ContentGroup>
      </ContentGroup>
    </HeroLayout>
  );
};

export default AddPropertyAccountDetails;
