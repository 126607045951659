import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from 'yw-ui.v2';
import { APP_URLS } from '../../../../common/settings';

const AddPropertyBreadcrumbs = ({ isPropertySelected, step }) => {
  const getCrumbs = () => {
    switch (step) {
      case 1: {
        if (isPropertySelected) {
          return [{ label: 'Home', to: APP_URLS.ROOT }, { label: 'Add property' }];
        }
        return [{ label: 'Add property' }];
      }
      case 2: {
        if (isPropertySelected) {
          return [
            { label: 'Home', to: APP_URLS.ROOT },
            { label: 'Add property', to: APP_URLS.ADD_PROPERTY },
            { label: 'Customer reference' },
          ];
        }
        return [{ label: 'Add property', to: APP_URLS.ADD_PROPERTY }, { label: 'Customer reference' }];
      }
      case 3: {
        if (isPropertySelected) {
          return [
            { label: 'Home', to: APP_URLS.ROOT },
            { label: 'Add property', to: APP_URLS.ADD_PROPERTY },
            { label: 'Customer reference', to: APP_URLS.ADD_PROPERTY_ACCOUNT_DETAILS },
            { label: 'Bill information' },
          ];
        }
        return [
          { label: 'Add property', to: APP_URLS.ADD_PROPERTY },
          { label: 'Customer reference', to: APP_URLS.ADD_PROPERTY_ACCOUNT_DETAILS },
          { label: 'Bill information' },
        ];
      }
      case 4: {
        if (isPropertySelected) {
          return [
            { label: 'Home', to: APP_URLS.ROOT },
            { label: 'Add property', to: APP_URLS.ADD_PROPERTY },
            { label: 'Customer reference', to: APP_URLS.ADD_PROPERTY_ACCOUNT_DETAILS },
            { label: 'Bill information', to: APP_URLS.ADD_PROPERTY_BILL_INFORMATION },
            { label: 'Check details' },
          ];
        }
        return [
          { label: 'Add property', to: APP_URLS.ADD_PROPERTY },
          { label: 'Customer reference', to: APP_URLS.ADD_PROPERTY_ACCOUNT_DETAILS },
          { label: 'Bill information', to: APP_URLS.ADD_PROPERTY_BILL_INFORMATION },
          { label: 'Check details' },
        ];
      }
      case 5: {
        if (isPropertySelected) {
          return [
            { label: 'Home', to: APP_URLS.ROOT },
            { label: 'Add property', to: APP_URLS.ADD_PROPERTY },
            { label: 'Customer reference' },
            { label: 'Bill information' },
            { label: 'Check details' },
            { label: 'Thanks' },
          ];
        }
        return [
          { label: 'Add property', to: APP_URLS.ADD_PROPERTY },
          { label: 'Customer reference' },
          { label: 'Bill information' },
          { label: 'Check details' },
          { label: 'Thanks' },
        ];
      }
      default: {
        return [{ label: 'Add property', to: APP_URLS.ADD_PROPERTY }];
      }
    }
  };

  return <Breadcrumbs showIcon={true} crumbs={getCrumbs()} />;
};

AddPropertyBreadcrumbs.propTypes = {
  isPropertySelected: PropTypes.bool,
  step: PropTypes.number,
};

export default AddPropertyBreadcrumbs;
