import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ContentGroup, ItemCard, Pagination, Breadcrumbs } from 'yw-ui.v2';
import { getFormattedAccountReference } from '../../common/functions';
import { usePageLoaded } from '../../common/hooks';
import { APP_URLS, MAX_PAGINATION_PAGE_SIZE } from '../../common/settings';
import { history } from '../../store';
import { tokenSelector } from '../../store/auth/selectors';
import { getProperties, getProperty, updatePropertiesIsLoading } from '../../store/properties/propertiesActions';
import {
  closedPropertiesListSelector,
  propertiesListSelector,
  selectedPropertySelector,
} from '../../store/properties/propertiesSelectors';
import { PageContentHeader } from '../components';
import { HeroLayout } from '../layouts';

const Properties = () => {
  const dispatch = useDispatch();
  const propertiesList = useSelector(propertiesListSelector);
  const selectedProperty = useSelector(selectedPropertySelector);
  const token = useSelector(tokenSelector);
  const { properties: closedProperties } = useSelector(closedPropertiesListSelector);
  const pageLoaded = usePageLoaded();

  useEffect(() => {
    if (!pageLoaded && token) {
      handlePagination(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLoaded, token]);

  const handlePagination = (page) => {
    dispatch(
      getProperties(page, MAX_PAGINATION_PAGE_SIZE, token, history, () => {
        dispatch(updatePropertiesIsLoading(false));
        window.scrollTo(0, 0);
      }),
    );
  };

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className="u-anim--fade-in properties-page">
        <PageContentHeader
          title="Your properties"
          breadcrumb={
            selectedProperty.accountReference && (
              <Breadcrumbs showIcon={true} crumbs={[{ label: 'Home', to: '/' }, { label: 'Your properties' }]} />
            )
          }
        />
        {propertiesList && propertiesList.totalProperties > 0 ? (
          propertiesList.properties.map((property, index) => (
            <ItemCard
              key={index}
              id={`property-${index}`}
              qa={`property-${index}`}
              iconLeftColour="ywDarkBlue"
              iconLeft="IconHouse"
              iconLeftSize="600"
              iconRight="IconChevronThick"
              iconRightSize="150"
              title={
                property.address
                  ? `
                                ${
                                  property.address.houseName
                                    ? `${property.address.houseName}, ${property.address.postcode}`
                                    : `${property.address.houseNumber} ${property.address.addressLine1}, ${property.address.postcode}`
                                } 
                            `
                  : 'Address not available'
              }
              subTitle={getFormattedAccountReference(property.displayAccountReference)}
              onClick={() => {
                dispatch(
                  getProperty(encodeURIComponent(property.accountReference), token, history, () => {
                    history.push(APP_URLS.ROOT);
                  }),
                );
              }}
            />
          ))
        ) : (
          <p>No properties available.</p>
        )}
        {propertiesList && propertiesList.totalProperties > 0 && (
          <Pagination
            id="propertiesPagination"
            qa="propertiesPagination"
            currentPage={propertiesList.pageNumber}
            itemsPerPage={MAX_PAGINATION_PAGE_SIZE}
            totalResults={propertiesList.totalProperties}
            onPaginate={handlePagination}
          />
        )}
        {closedProperties.length > 0 && (
          <p className="closed-properties-text">
            Looking for bills and payments from a{' '}
            <NavLink to={APP_URLS.PREVIOUS_PROPERTIES}>previous property?</NavLink>
          </p>
        )}
      </ContentGroup>
    </HeroLayout>
  );
};

export default Properties;
