export const constraints = {
  nameOnBill: {
    presence: { allowEmpty: false, message: 'Please enter the first line of the name as it appears on the bill' },
    nameOnBillValidator: {
      message: {
        pattern: 'Please remove any special characters such as: &, é, ö.',
        length: 'First name must be at least 2 characters long.',
      },
    },
  },
  dateOnBill: {
    dateOnBillValidator: {
      message: {
        empty: 'Please select a date',
        pattern: 'Date on bill must be in the format DD/MM/YYYY',
      },
    },
  },
};
