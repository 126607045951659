import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentGroup, Row, Col, Breadcrumbs } from 'yw-ui.v2';
import { SOMETHING_WENT_WRONG_ACTION } from '../../common/constants/somethingWentWrongAction';
import icecreamImg from '../../common/images/ICE_CREAM_570x360_72dpi.png';
import { HeroLayout } from '../layouts';
import { PageContentHeader } from '../components';

const SomethingWentWrong = () => {
  const params = useParams();

  const getTitle = () => {
    switch (params.id) {
      case SOMETHING_WENT_WRONG_ACTION.ADD_PROPERTY:
      case SOMETHING_WENT_WRONG_ACTION.VALIDATE_PROPERTY: {
        return 'Add a property';
      }
      case SOMETHING_WENT_WRONG_ACTION.BILLS_AND_PAYMENTS: {
        return 'Bills & payments';
      }
      case SOMETHING_WENT_WRONG_ACTION.CUSTOMER_PREFERENCES: {
        return 'Update contact information';
      }
      case SOMETHING_WENT_WRONG_ACTION.GET_CUSTOMER: {
        return 'My profile';
      }
      case SOMETHING_WENT_WRONG_ACTION.GET_PROPERTY: {
        return 'Your properties';
      }
      case SOMETHING_WENT_WRONG_ACTION.SWITCH_TO_PAPERLESS: {
        return 'Billing preferences';
      }
      default:
        return '';
    }
  };

  const getMessageContent = () => {
    return (
      <div>
        <p>There&apos;s been a problem and you can&apos;t use your online account at the moment.</p>
        <p>Please close this tab and try again later.</p>
      </div>
    );
  };
  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className="u-anim--fade-in something-went-wrong">
        <PageContentHeader
          title={getTitle()}
          breadcrumb={<Breadcrumbs showIcon={true} crumbs={[{ label: 'Home', to: '/' }, { label: getTitle() }]} />}
        />
        <Row>
          <Col className="content-left-wrapper" sm={6} xs={12}>
            <div>
              <div className="header-text">
                Something <br /> went <br /> <span>wrong</span>
              </div>

              {getMessageContent()}
            </div>
          </Col>
          <Col className="content-right-wrapper" sm={6} xs={12}>
            <img src={icecreamImg} alt="something did go wrong" />
          </Col>
        </Row>
      </ContentGroup>
    </HeroLayout>
  );
};

export default SomethingWentWrong;
