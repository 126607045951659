import { ADD_PROPERTY_ACTIONS } from './addPropertyActions';

export const INITIAL_STATE = {
  loading: false,
  formStarted: false,
  formCompleted: false,
  formIsEditing: false,
  addPropertyFormData: {
    customerReferenceNumber: '',
    postcode: '',
    nameOnBill: '',
    dateOnBill: '',
  },
  submitFormResponse: null,
  validateReferenceResponse: null,
  error: null,
};

export const addPropertyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PROPERTY_ACTIONS.UPDATE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case ADD_PROPERTY_ACTIONS.UPDATE_FORM_STARTED: {
      return {
        ...state,
        formStarted: action.payload,
      };
    }
    case ADD_PROPERTY_ACTIONS.UPDATE_FORM_COMPLETED: {
      return {
        ...state,
        formCompleted: action.payload,
      };
    }
    case ADD_PROPERTY_ACTIONS.UPDATE_FORM_IS_EDITING: {
      return {
        ...state,
        formIsEditing: action.payload,
      };
    }
    case ADD_PROPERTY_ACTIONS.UPDATE_ADD_PROPERTY_FORM_DATA: {
      return {
        ...state,
        addPropertyFormData: action.payload,
      };
    }
    case ADD_PROPERTY_ACTIONS.UPDATE_FORM_SUBMITTED_RESPONSE: {
      return {
        ...state,
        submitFormResponse: action.payload,
      };
    }
    case ADD_PROPERTY_ACTIONS.UPDATE_VALIDATE_REFERENCE_RESPONSE: {
      return {
        ...state,
        validateReferenceResponse: action.payload,
      };
    }
    case ADD_PROPERTY_ACTIONS.UPDATE_FORM_SUBMITTED_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case ADD_PROPERTY_ACTIONS.RESET_FORM: {
      return {
        ...INITIAL_STATE,
        formCompleted: true,
      };
    }
    default: {
      return state;
    }
  }
};
