import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ContentGroup, Breadcrumbs, FluidForm, FluidField, Message, AccordionSingle } from 'yw-ui.v2';
import { PageContentHeader } from '../components';
import { HeroLayout } from '../layouts';
import { selectedPropertySelector } from '../../store/properties/propertiesSelectors';
import { BILL_PREFERENCE, constraint, customValidators } from './settings';
import { ButtonControls } from '../components';
import { history } from '../../store';
import { APP_URLS, YW_URLS } from '../../common/settings';
import { switchToPaperlessBilling } from '../../store/properties/propertiesActions';
import { tokenSelector } from '../../store/auth/selectors';
import { NavLink } from 'react-router-dom';
import { handlePushToDataLayer } from '../../common/functions/helpers';

const BillingPreferences = () => {
  const dispatch = useDispatch();
  const selectedProperty = useSelector(selectedPropertySelector);
  const token = useSelector(tokenSelector);
  const [selectedBillPreference, setSelectedBillPreference] = useState(
    selectedProperty?.isBillingPaperless ? BILL_PREFERENCE.EMAIL : BILL_PREFERENCE.POST,
  );
  const [billPreferenceSuccessful, setBillPreferenceSuccessful] = useState(false);
  const [billPreferenceError, setBillPreferenceError] = useState(false);

  const onHandleBillPreferenceChange = ({ selectedBillPreference }) => {
    setSelectedBillPreference(selectedBillPreference);
  };

  const submitDataLayer = (state) => {
    handlePushToDataLayer({
      event: 'bill_preference_form_submit',
      stepName: APP_URLS.BILL_PREFERENCES,
      state: state,
      other: {
        step: 1,
      },
    });
  };

  const onSubmit = ({ selectedBillPreference }) => {
    const isPaperless = selectedBillPreference === BILL_PREFERENCE.EMAIL ? true : false;
    dispatch(
      switchToPaperlessBilling(
        selectedProperty.accountReference,
        isPaperless,
        token,
        history,
        (response) => {
          if (response.status === 200) {
            setBillPreferenceSuccessful(true);
            setBillPreferenceError(false);
            submitDataLayer('success');
          } else {
            setBillPreferenceSuccessful(false);
            setBillPreferenceError(true);
            submitDataLayer('failure');
          }
        },
        () => submitDataLayer('failure'),
      ),
    );
  };

  const onCancel = () => {
    history.push(APP_URLS.ROOT);
  };

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className="u-anim--fade-in bill-preferences-scene">
        <PageContentHeader
          title="Billing preferences"
          breadcrumb={
            <Breadcrumbs showIcon={true} crumbs={[{ label: 'Home', to: '/' }, { label: 'Billing preferences' }]} />
          }
        />
        <Message title="Need a copy of your bill?" id="copy-of-bill" qa="copy-of-bill" className="u-p--300 u-mb--200">
          <p>
            You can download or print your bills by visiting{' '}
            <NavLink to={APP_URLS.BILLS_AND_PAYMENTS}>bills and payments</NavLink>.
          </p>
        </Message>
        <p className="u-mb--100 u-mt--400">
          <strong>How do you want to receive your bills</strong>
        </p>
        <FluidForm
          constraints={constraint(
            selectedBillPreference,
            selectedProperty.isBillingPaperless,
            selectedProperty.isEligibleForPaperlessBilling,
          )}
          initialValues={{
            selectedBillPreference,
            isBillingPaperless: selectedProperty.isBillingPaperless,
            isEligibleForPaperlessBilling: selectedProperty.isEligibleForPaperlessBilling,
          }}
          customValidators={customValidators}
          onChange={onHandleBillPreferenceChange}
          onSubmit={onSubmit}
          suppressHtmlValidation
          className="bill-preference-form"
        >
          <FluidField
            id="selectedBillPreference"
            name="selectedBillPreference"
            qa="selectedBillPreference"
            component="radiotile"
            options={[
              { id: BILL_PREFERENCE.POST, label: 'Post' },
              { id: BILL_PREFERENCE.EMAIL, label: `Email (to ${selectedProperty.email})` },
            ]}
            label=""
            disabled={!selectedProperty.isEligibleForPaperlessBilling}
          />
          <AccordionSingle
            title="Change email"
            className="u-mt--300 u-mb--400"
            qa="change-email-accordion"
            id="change-email-accordion"
          >
            <ContentGroup className="u-bg--ywLightGrey u-p--400 c-border">
              <p>
                If you want to use a different email address, you&apos;ll need to register for a new online account.
              </p>
              <p className="u-mb--100">
                It&apos;s quick and easy, just make sure you&apos;ve got your customer reference number and property
                postcode handy.{' '}
              </p>
              <a href={YW_URLS.REGISTER_AN_ACCOUNT} target="_blank" rel="noopener noreferrer">
                Register for a new online account
              </a>
            </ContentGroup>
          </AccordionSingle>
          {billPreferenceSuccessful && !billPreferenceError && (
            <Message
              id="billing-preference-update-success"
              qa="billing-preference-update-success"
              title=""
              state="success"
              className="u-mt--300 u-mb--300"
            >
              <p>Billing preference updated.</p>
            </Message>
          )}
          {billPreferenceError && !billPreferenceSuccessful && (
            <Message
              id="billing-preference-update-error"
              qa="billing-preference-update-error"
              title=""
              state="error"
              className="u-mt--300 u-mb--300"
            >
              <p>Billing preference update failed. Please try again later.</p>
            </Message>
          )}
          <ButtonControls onBack={onCancel} renderBack={true} nextLabel="Update" />
        </FluidForm>
      </ContentGroup>
    </HeroLayout>
  );
};

export default BillingPreferences;
