import React, { useState, useEffect, StrictMode } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { PrivateRoute } from 'fluid-auth';
import { Loader } from 'yw-ui.v2';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { APP_URLS, INACTIVITY_TIMEOUT, INACTIVITY_TIMEOUT_PROMPT, TELEMETRY } from './common/settings';
import useTelemetry from './common/hooks/useTelemetry';
import * as SCENES from './scenes';
import { MainLayout } from './scenes/layouts/';

import './common/styles/index.scss';
import { isAuthenticatedSelector, authHasErrorSelector, authIsExpiringSelector } from './store/auth/selectors';
import { propertiesIsLoadingSelector } from './store/properties/propertiesSelectors';
import { loadingMessageSelector } from './store/app/selectors';
import { billsPaymentsIsLoadingSelector } from './store/billsPayments/billsPaymentsSelector';
import { AddPropertyFormRoute } from './scenes/hoc';
import {
  addPropertyFormStartedSelector,
  addPropertyFormCompletedSelector,
} from './store/addProperty/addPropertySelector';
import { useAppAuth, useFetchProperties } from './common/hooks';
import InactivityTimer from './scenes/components/InactivityTimer/InactivityTimer';

function App() {
  useTelemetry(TELEMETRY.INSTRUMENTATION_KEY, TELEMETRY.IS_ENABLED, TELEMETRY.REPLACE_TITLE);

  const history = useHistory();
  const { pathname } = useLocation();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const propertiesIsLoading = useSelector(propertiesIsLoadingSelector);
  const billsPaymentsIsLoading = useSelector(billsPaymentsIsLoadingSelector);
  const loadingMessage = useSelector(loadingMessageSelector);
  const authHasError = useSelector(authHasErrorSelector);
  const authIsExpiring = useSelector(authIsExpiringSelector);
  const addPropertyFormStarted = useSelector(addPropertyFormStartedSelector);
  const addPropertyFormCompleted = useSelector(addPropertyFormCompletedSelector);

  const [routesConfig] = useState([
    { route: APP_URLS.ROOT, component: SCENES.Home },
    { route: APP_URLS.PROPERTIES, component: SCENES.Properties },
    { route: APP_URLS.PREVIOUS_PROPERTIES, component: SCENES.PreviousProperties },
    { route: APP_URLS.ADD_PROPERTY, component: SCENES.AddPropertyStart },
    { route: APP_URLS.YOUR_BALANCE, component: SCENES.YourBalance },
    { route: APP_URLS.BILL_PREFERENCES, component: SCENES.BillingPreferences },
    { route: APP_URLS.BILLS_AND_PAYMENTS, component: SCENES.BillsAndPayments },
    { route: APP_URLS.MY_PROFILE, component: SCENES.MyProfile },
    { route: APP_URLS.CONTACT_INFORMATION, component: SCENES.ContactInformation },
    { route: APP_URLS.CHANGE_EMAIL, component: SCENES.ChangeEmail },
    { route: `${APP_URLS.SOMETHING_WENT_WRONG}/:id`, component: SCENES.SomethingWentWrong },
    { route: APP_URLS.SOMETHING_WENT_WRONG, component: SCENES.SomethingWentWrong },
    { route: APP_URLS.ACCOUNT_DISABLED, component: SCENES.AccountDisabled },
    { route: APP_URLS.ACCOUNT_SETUP_IN_PROGRESS, component: SCENES.AccountSetupInProgress },
  ]);
  const [addPropertyRoutesConfig] = useState([
    { route: APP_URLS.ADD_PROPERTY_ACCOUNT_DETAILS, component: SCENES.AddPropertyAccountDetails },
    { route: APP_URLS.ADD_PROPERTY_BILL_INFORMATION, component: SCENES.AddPropertyBillInformation },
    { route: APP_URLS.ADD_PROPERTY_CHECK_DETAILS, component: SCENES.AddPropertyCheckDetails },
    { route: APP_URLS.ADD_PROPERTY_CONFIRMATION, component: SCENES.AddPropertyConfirmation },
  ]);

  const doLogOut = useAppAuth(); // Custom hook for handling user account authentication.
  const appLoaded = useFetchProperties(); // Custom hook to fetch properties

  useEffect(() => {
    // Handle redirects to start page on hard refresh
    if (
      appLoaded &&
      isAuthenticated &&
      (pathname.includes(APP_URLS.CALLBACK_RESPONSE) || pathname.includes(APP_URLS.REDIRECT))
    ) {
      history.push(APP_URLS.ROOT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, appLoaded, pathname]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (authHasError && authIsExpiring) {
        // Clear session storage and redirect to login if auth has error.
        sessionStorage.clear();
        window.location.reload();
      }
    }
  }, [authHasError, authIsExpiring]);

  return (
    <StrictMode>
      <MainLayout>
        <InactivityTimer
          title="Your session is about to end"
          promptTime={INACTIVITY_TIMEOUT}
          timeBeforePrompt={INACTIVITY_TIMEOUT_PROMPT}
          ignoreTimer={false}
          onIdle={doLogOut}
        >
          <p>
            You&apos;ve been inactive for a while. For your security, we&apos;ll automatically sign you out in
            approximately 1 minute. Choose &quot;Stay signed in&quot; to continue or &quot;Sign out&quot; if you&apos;re
            done.
          </p>
        </InactivityTimer>
        {(propertiesIsLoading || billsPaymentsIsLoading) && (
          <Loader message={loadingMessage ? `${loadingMessage}...` : 'Loading...'} />
        )}
        <Switch>
          {routesConfig.map(({ route, component: Component }) => (
            <PrivateRoute key={route} path={route} render={() => <Component />} component={<Component />} exact />
          ))}
          {addPropertyRoutesConfig.map(({ route, component }) => (
            <AddPropertyFormRoute
              key={route}
              path={route}
              component={component}
              formStarted={addPropertyFormStarted}
              formCompleted={addPropertyFormCompleted}
            />
          ))}
          ;
          <PrivateRoute path="*" component={({ Component = routesConfig[0].component }) => <Component />} />
        </Switch>
      </MainLayout>
    </StrictMode>
  );
}

export default App;
