import RestClient from '../../common/modules/rest';
import { API } from '../../common/settings';
import { createToken } from '../../common/functions';
import { BASE_URL } from '../../common/settings/api';

const rest = new RestClient();
const billsPaymentsEndPoint = API.API_ENDPOINTS.BILLS_PAYMENTS;

export const fetchBillsPayments = (accountReference, maxPayments, token) =>
  rest.get(
    `${billsPaymentsEndPoint}?accountReference=${accountReference}&maxPayments=${maxPayments}`,
    createToken(token),
  );

export const fetchBillInPDF = (link, token) =>
  rest.get(`${BASE_URL}${link}`, { ...createToken(token), Accept: 'application/pdf' }, 'arraybuffer');
