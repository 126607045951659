import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { connectedAuth } from 'fluid-auth';
import { addPropertyReducer } from './addProperty/addPropertyReducer';
import { APP_BASENAME } from '../common/settings';
import { appReducer } from './app/appReducer';
import { customerReducer } from './customer/customerReducer';
import { billsPaymentsReducer } from './billsPayments/billsPaymentsReducer';
import { propertiesReducer } from './properties/propertiesReducer';

const createRootReducer = (history) =>
  combineReducers({
    app: appReducer,
    auth: connectedAuth(),
    addProperty: addPropertyReducer,
    billsPayments: billsPaymentsReducer,
    customer: customerReducer,
    properties: propertiesReducer,
    router: connectRouter(history),
  });

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['properties'],
  blacklist: ['auth', 'app'],
};

const historyConfig = { basename: APP_BASENAME };
export const history = createBrowserHistory(historyConfig);
const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk)),
);

export const persistor = persistStore(store);
