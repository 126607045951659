import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs, Button, FluidForm, FluidField, ContentGroup, Loader, ScrollTo, Message } from 'yw-ui.v2';
import { HeroLayout } from '../layouts';
import { ButtonControls, PageContentHeader } from '../components';
import { APP_URLS, YW_URLS } from '../../common/settings';
import { customerIsLoadingSelector, customerResponseSelector } from '../../store/customer/customerSelectors';
import { getCustomerDetails, submitCustomerPreferences } from '../../store/customer/customerActions';
import { tokenSelector } from '../../store/auth/selectors';
import { history } from '../../store';
import { RADIO_BOOL } from '../../store/customer/customerReducer';
import { usePageLoaded } from '../../common/hooks';
import {
  closedPropertiesListSelector,
  propertiesListSelector,
  selectedPropertySelector,
} from '../../store/properties/propertiesSelectors';
import { handlePushToDataLayer } from '../../common/functions/helpers';

const MyProfile = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const { forename, surname, email, mobileTelephone, serviceFeedback, supplyIssues } = useSelector(
    customerResponseSelector,
  );
  const customerIsLoading = useSelector(customerIsLoadingSelector);
  const selectedProperty = useSelector(selectedPropertySelector);
  const { properties } = useSelector(propertiesListSelector);
  const { closedProperties = properties } = useSelector(closedPropertiesListSelector);

  const pageLoaded = usePageLoaded();
  const [isSUbmittingUpdate, setIsSUbmittingUpdate] = useState(false);
  const [updateCompleted, setUpdateCompleted] = useState(false);

  useEffect(() => {
    if (!pageLoaded) {
      if (token) {
        dispatch(getCustomerDetails(token, history));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLoaded, token]);

  const getBreadCrumbs = () => {
    if (selectedProperty.accountReference) {
      return [{ label: 'Home', to: APP_URLS.ROOT }, { label: 'My profile' }];
    } else if (properties.length > 0) {
      return [{ label: 'Your properties', to: APP_URLS.PROPERTIES }, { label: 'My profile' }];
    } else if (closedProperties.length > 0) {
      return [{ label: 'Previous properties', to: APP_URLS.PROPERTIES }, { label: 'My profile' }];
    }
    return [{ label: 'Add property', to: APP_URLS.ADD_PROPERTY }, { label: 'My profile' }];
  };

  // const onHandleChangeEmail = () => {
  //   history.push(APP_URLS.CHANGE_EMAIL);
  // };
  const onHandleChangePassword = () => {
    window.location.href = YW_URLS.CHANGE_PASSWORD;
  };

  const submitDataLayer = (state) => {
    handlePushToDataLayer({
      event: 'customer_preference_form_submit',
      stepName: APP_URLS.MY_PROFILE,
      state: state,
      other: {
        step: 1,
      },
    });
  };

  const onSubmit = (value) => {
    const params = {
      serviceFeedback: value.serviceFeedback === RADIO_BOOL.YES ? true : false,
      supplyIssues: value.supplyIssues === RADIO_BOOL.YES ? true : false,
    };
    setIsSUbmittingUpdate(true);
    dispatch(
      submitCustomerPreferences(
        params,
        token,
        history,
        (response) => {
          submitDataLayer('success');
          setIsSUbmittingUpdate(false);
          setUpdateCompleted(true);
        },
        () => submitDataLayer('failure'),
      ),
    );
  };

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className={`u-anim--fade-in contact-information-wrapper`}>
        <PageContentHeader title="My profile" breadcrumb={<Breadcrumbs showIcon={true} crumbs={getBreadCrumbs()} />} />
        {customerIsLoading && (
          <Loader message={isSUbmittingUpdate ? 'Updating your details...' : 'Loading your details...'} />
        )}
        <ContentGroup marginTop="300">
          <p className="u-mt--300">
            <strong>Account name</strong>
            <br />
            <span>
              {forename} {surname}
            </span>
          </p>
          <p className="u-mt--300">
            <strong>Mobile number</strong>
            <br />
            <span>{mobileTelephone && mobileTelephone.trim().length > 0 ? mobileTelephone : 'None'}</span>
          </p>
          <p className="u-mt--300">
            <strong>Email address</strong>
            <br />
            <span>{email && email.trim().length > 0 ? email : 'None'}</span>
          </p>
          {/* {email && (
            <Button
              type="button"
              label="Change"
              state="default-outline"
              onClick={onHandleChangeEmail}
              id="change-email-button"
              qa="change-email-button"
            />
          )} */}
          <p className="u-mt--300">
            <strong>Password</strong>
            <br />
            <span>***************</span>
          </p>
          <Button
            type="button"
            label="Change"
            state="default-outline"
            onClick={onHandleChangePassword}
            id="change-password-button"
            qa="change-password-button"
          />
          {selectedProperty.accountReference && (
            <>
              <p className="u-mt--600">
                <strong>Contact preferences</strong>
              </p>
              <FluidForm
                initialValues={{ mobileTelephone, serviceFeedback, supplyIssues }}
                onSubmit={onSubmit}
                suppressHtmlValidation
              >
                <span>Are you happy to get messages about customer service feedback?</span>
                <FluidField
                  component="radiotile"
                  id="serviceFeedback"
                  qa="serviceFeedback"
                  name="serviceFeedback"
                  options={[
                    { id: RADIO_BOOL.YES, label: 'Yes' },
                    { id: RADIO_BOOL.NO, label: 'No' },
                  ]}
                  label=""
                />
                <span>Do you want to get messages about water supply issues?</span>
                <FluidField
                  component="radiotile"
                  id="supplyIssues"
                  qa="supplyIssues"
                  name="supplyIssues"
                  options={[
                    { id: RADIO_BOOL.YES, label: 'Yes' },
                    { id: RADIO_BOOL.NO, label: 'No' },
                  ]}
                  label=""
                />
                {updateCompleted && (
                  <ScrollTo scrollCount={1}>
                    <ContentGroup className="u-anim--flash">
                      <Message
                        title="Update successful"
                        state="success"
                        id="update-success-message"
                        qa="update-success-message"
                      >
                        Your contact preference was updated successfully.
                      </Message>
                    </ContentGroup>
                  </ScrollTo>
                )}
                <ButtonControls nextLabel="Submit" renderBack={false} />
              </FluidForm>
            </>
          )}
        </ContentGroup>
      </ContentGroup>
    </HeroLayout>
  );
};

export default MyProfile;
