import { SOMETHING_WENT_WRONG_ACTION } from '../../common/constants/somethingWentWrongAction';
import { downloadPDFDocument } from '../../common/functions';
import { handleErrorRedirect } from '../../common/functions/helpers';
import { updateLoadingMessage } from '../app/appActions';
import { fetchBillInPDF, fetchBillsPayments } from './billsPaymentsService';

export const BILLS_PAYMENTS_ACTIONS = {
  UPDATE_LOADING: 'BILLS_PAYMENTS_ACTIONS.UPDATE_LOADING',
  UPDATE_BILLS_PAYMENTS_RESPONSE: 'BILLS_PAYMENTS_ACTIONS.UPDATE_BILLS_PAYMENTS_RESPONSE',
  UPDATE_BILLS_PAYMENTS_ERROR: 'BILLS_PAYMENTS_ACTIONS.UPDATE_BILLS_PAYMENTS_ERROR',
  RESET_BILLS_PAYMENTS: 'BILLS_PAYMENTS_ACTIONS.RESET_BILLS_PAYMENTS',
  UPDATE_DOWNLOADED_BILL_PDF: 'BILLS_PAYMENTS_ACTIONS.UPDATE_DOWNLOADED_BILL_PDF',
};

export const updateBillsPaymentsIsLoading = (state) => ({
  type: BILLS_PAYMENTS_ACTIONS.UPDATE_LOADING,
  payload: state,
});

export const updateBillsPaymentsResponse = (response) => ({
  type: BILLS_PAYMENTS_ACTIONS.UPDATE_BILLS_PAYMENTS_RESPONSE,
  payload: response,
});

export const updateBillsPaymentsError = (error) => ({
  type: BILLS_PAYMENTS_ACTIONS.UPDATE_BILLS_PAYMENTS_ERROR,
  payload: error,
});

export const updateDownloadedBillPDF = (billData) => ({
  type: BILLS_PAYMENTS_ACTIONS.UPDATE_DOWNLOADED_BILL_PDF,
  payload: billData,
});

export const resetBillsPayments = () => ({
  type: BILLS_PAYMENTS_ACTIONS.RESET_BILLS_PAYMENTS,
});

export const getBillsAndPayments = (accountReference, maxPayments, token, history) => async (dispatch) => {
  const successHandler = (response) => {
    if (response && response.data) {
      dispatch(updateBillsPaymentsResponse(response.data));
      dispatch(updateBillsPaymentsIsLoading(false));
    }
  };

  const errorHandler = (error) => {
    dispatch(updateBillsPaymentsIsLoading(false));
    handleErrorRedirect(error, SOMETHING_WENT_WRONG_ACTION.BILLS_AND_PAYMENTS, history, () => {
      dispatch(updateBillsPaymentsError(error));
    });
  };

  dispatch(updateBillsPaymentsIsLoading(true));
  dispatch(updateLoadingMessage('Loading bills and payments'));

  try {
    const response = await fetchBillsPayments(
      encodeURIComponent(accountReference.replace(/ /g, '')),
      maxPayments,
      token,
    );
    response ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    error.status ? errorHandler(error.status) : errorHandler(500);
  } finally {
    dispatch(updateLoadingMessage(''));
  }
};

export const getBillInPDF = (fileName, link, token) => async (dispatch) => {
  const successHandler = (response) => {
    if (response.data) {
      downloadPDFDocument(fileName, response.data);
      dispatch(updateDownloadedBillPDF({ [link]: response.data }));
    }
    dispatch(updateBillsPaymentsIsLoading(false));
  };

  const errorHandler = (error) => {
    dispatch(updateBillsPaymentsIsLoading(false));
  };

  dispatch(updateBillsPaymentsIsLoading(true));
  dispatch(updateLoadingMessage('Loading bill'));

  try {
    const response = await fetchBillInPDF(link, token);
    response ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    error.status ? errorHandler(error.status) : errorHandler(500);
  } finally {
    dispatch(updateLoadingMessage(''));
  }
};
