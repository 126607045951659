import { useDispatch } from 'react-redux';
import { useAuth } from 'fluid-auth';
import { resetProperties, updatePropertiesIsLoading } from '../../store/properties/propertiesActions';
import { resetApp, updateLoadingMessage } from '../../store/app/appActions';

const useAppAuth = () => {
  const { signOut } = useAuth();
  const dispatch = useDispatch();

  const doLogOut = (message = '') => {
    dispatch(resetProperties());
    dispatch(resetApp());
    dispatch(updateLoadingMessage(message ? message : 'Logging out'));
    dispatch(updatePropertiesIsLoading(true));
    signOut();
  };

  return doLogOut;
};

export default useAppAuth;
