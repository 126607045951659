import React from 'react';
import PropTypes from 'prop-types';

const HeroLayout = ({ heroCollapse, children }) => {
  return (
    <div className={`c-app ${!heroCollapse ? 'home-page' : ''}`}>
      <div className="hero-layout">
        <div className={`hero-banner ${heroCollapse ? 'collapsed' : ''}`} />
        <div className={`o-container ${heroCollapse ? 'hero-banner-collapsed' : 'home-page'}`}>
          <main className="c-main" role="main" id="maincontent">
            <div className={heroCollapse ? 'c-box' : 'c-box-home'}>{children}</div>
          </main>
        </div>
      </div>
    </div>
  );
};

HeroLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  heroCollapse: PropTypes.bool,
};

export default HeroLayout;
