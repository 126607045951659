import { NAME_REGEX } from '../../../../../common/settings/regex';

export const customValidators = {
  nameOnBillValidator: (value, options) => {
    if (value) {
      if (!NAME_REGEX.test(value)) {
        return options.message.pattern;
      } else if (value.length < 2 || value.length > 255) {
        return options.message.length;
      }
    }
  },
  dateOnBillValidator: (value, options) => {
    if (!value) {
      return options.message.empty;
    }
  },
};
