import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentGroup, FluidForm, FluidField, DatePicker, Row, Col, AccordionSingle } from 'yw-ui.v2';
import { history } from '../../../../store';
import { APP_URLS } from '../../../../common/settings';
import { ButtonControls, PageContentHeader } from '../../../components';
import { HeroLayout } from '../../../layouts';
import {
  addPropertyFormDataSelector,
  addPropertyFormIsEditingSelector,
} from '../../../../store/addProperty/addPropertySelector';
import { constraints, customValidators } from './validation';
import { updateAddPropertyFormData } from '../../../../store/addProperty/addPropertyActions';
import { selectedPropertySelector } from '../../../../store/properties/propertiesSelectors';
import { AddPropertyBreadcrumbs } from '../../components';
import { formatDate, handlePushToDataLayer } from '../../../../common/functions/helpers';
import bill_date_image from '../../../../common/images/bill_date_image.png';

const AddPropertyBillInformation = () => {
  const dispatch = useDispatch();
  const selectedProperty = useSelector(selectedPropertySelector);
  const { customerReferenceNumber, postcode, nameOnBill, dateOnBill } = useSelector(addPropertyFormDataSelector);
  const isEditing = useSelector(addPropertyFormIsEditingSelector);

  const goBack = () => {
    history.goBack();
  };

  const onSubmit = async ({ nameOnBill, dateOnBill }) => {
    const params = { customerReferenceNumber, postcode, nameOnBill, dateOnBill };
    dispatch(updateAddPropertyFormData(params));
    handlePushToDataLayer({
      event: 'form_step2',
      stepName: APP_URLS.ADD_PROPERTY_BILL_INFORMATION,
      state: 'success',
      other: {
        step: 2,
      },
    });
    history.push(APP_URLS.ADD_PROPERTY_CHECK_DETAILS);
  };

  const nameOnBillHint = () => (
    <>
      <span>
        This needs to be an exact match of the title(s) and full name(s) in the first line on your bill (you must
        replicate upper/lower case and symbols) for example:
      </span>
      <br />
      <span>Mr Joe Bloggs &</span>
    </>
  );

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className="u-anim--fade-in bill-information-page">
        <PageContentHeader
          title="Bill information"
          breadcrumb={
            <AddPropertyBreadcrumbs isPropertySelected={selectedProperty.accountReference.length > 0} step={3} />
          }
        />
        <ContentGroup marginTop="300">
          <FluidForm
            constraints={constraints}
            initialValues={{ nameOnBill, dateOnBill }}
            customValidators={customValidators}
            onSubmit={onSubmit}
            suppressHtmlValidation
          >
            {({ handleChange, values }) => {
              const { dateOnBill } = values;

              const handleDateChange = (value) => {
                if (!value) {
                  return;
                }
                handleChange('dateOnBill', formatDate(value));
              };
              return (
                <>
                  <Row>
                    <Col sm={6}>
                      <FluidField
                        label="Name on the bill"
                        hint={nameOnBillHint()}
                        id="nameOnBill"
                        name="nameOnBill"
                        qa="nameOnBill"
                        data-testid="nameOnBill"
                        component="text"
                        condensed={false}
                        isRequiredMarker
                      />
                      <FluidField
                        label="Date on the bill"
                        hint="Date on your latest bill or statement"
                        id="dateOnBill"
                        name="dateOnBill"
                        qa="dateOnBill"
                        data-testid="dateOnBill"
                        component="text"
                        isRequiredMarker
                        condensed={false}
                      >
                        {() => {
                          return (
                            <DatePicker
                              id="dateOnBill_date_picker"
                              qa="dateOnBill_date_picker"
                              date={dateOnBill}
                              toMonth={new Date()}
                              handleDateChange={handleDateChange}
                            />
                          );
                        }}
                      </FluidField>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8}>
                      <AccordionSingle
                        title="Where can I find the date on my bill?"
                        className="accordion-single-toggle"
                        qa="date-on-bill-accordion"
                        id="date-on-bill-accordion"
                      >
                        <ContentGroup className="u-bg--ywLightGrey u-p--200">
                          <ContentGroup marginTop="100" marginBottom="200">
                            <p>
                              The date of your bill is shown under the address on the left side of your last bill or
                              statement.
                            </p>
                          </ContentGroup>
                          <img
                            src={bill_date_image}
                            className="u-mb--100"
                            alt="The date of your bill is shown under the address on the left side of your last bill or
                            statement"
                          />
                        </ContentGroup>
                      </AccordionSingle>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <ButtonControls onBack={goBack} nextLabel={isEditing ? 'Save' : 'Next'} renderBack={!isEditing} />
                    </Col>
                  </Row>
                </>
              );
            }}
          </FluidForm>
        </ContentGroup>
      </ContentGroup>
    </HeroLayout>
  );
};

export default AddPropertyBillInformation;
