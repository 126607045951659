import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ContentGroup, Heading, Row, Col, Message, ActionTile, ModalDialog } from 'yw-ui.v2';

import { closedPropertiesListSelector, selectedPropertySelector } from '../../store/properties/propertiesSelectors';
import { getFormatAddressDetails, getFormattedAccountReference, getLongDateFormat } from '../../common/functions';
import { APP_URLS, YW_URLS } from '../../common/settings';
import { HeroLayout } from '../layouts';
import { HeroTiles } from '../components';
import { NavLink } from 'react-router-dom';
import { getMovingHomeURL, isLoggedInMoveWithinVisible } from '../../common/settings/urls';

const Home = () => {
  const selectedProperty = useSelector(selectedPropertySelector);
  const { properties: closedProperties } = useSelector(closedPropertiesListSelector);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const showMakeAPaymentTile = () => {
    let showTile = true;

    if (selectedProperty) {
      if (selectedProperty.isClosed) {
        if (Number(selectedProperty.balanceOutstanding) <= 0) {
          showTile = false;
        }
      } else {
        if (selectedProperty.isPaymentPlan) {
          showTile = false;
        }
      }
    }
    return showTile;
  };

  const getManagePropertyNotice = () => {
    let notice = '';

    if (selectedProperty) {
      if (selectedProperty.isClosed) {
        if (Number(selectedProperty.balanceOutstanding) >= 0) {
          notice = 'Closed account information is available to view online for 2 years.';
        }
      } else {
        if (selectedProperty.isMetered) {
          if (selectedProperty.isDirectDebit || !selectedProperty.isDirectDebit) {
            notice = '';
          }
        } else {
          if (selectedProperty.isDirectDebit || !selectedProperty.isDirectDebit) {
            notice =
              'When you make a payment your online account will not be updated in real-time, Please allow for a couple of days to see whether the payment has been processed.';
          }
        }
      }
    }
    return notice;
  };

  return (
    <HeroLayout heroCollapse={false}>
      <ContentGroup className="u-anim--fade-in home-detail-wrapper">
        <Row className="customer-details">
          <Col md={12}>
            <Heading id="userName" size="xl" title={`Hello ${selectedProperty.firstName}`} />
            <Heading id="userAddress" size="md" title={getFormatAddressDetails(selectedProperty.address)} />
          </Col>
        </Row>
        <Row className="customer-details u-mb--100">
          <Col md={6}>
            <p id="accountReference">
              <strong>Customer reference number:</strong>&nbsp;
              <span>{getFormattedAccountReference(selectedProperty.displayAccountReference)}</span>
            </p>
          </Col>
          <Col md={6}>
            <p id="accountStartDate">
              <strong>Account opened:</strong>&nbsp;
              <span>{getLongDateFormat(selectedProperty.accountStartDate)}</span>
            </p>
          </Col>
        </Row>
        <HeroTiles selectedProperty={selectedProperty} />
      </ContentGroup>
      <ContentGroup className="manage-property">
        <ContentGroup className="c-heading-block u-mt--600 u-mb--400">
          <Heading size="xl" title="Manage your property" />
        </ContentGroup>
        {getManagePropertyNotice() && (
          <ContentGroup>
            <Message
              title="Please note"
              id="manage-property-notice"
              qa="manage-property-notice"
              className="u-p--300 u-mt--300 u-mb--300"
            >
              <p>{getManagePropertyNotice()}</p>
            </Message>
          </ContentGroup>
        )}
        <Row className="u-mb--200 action-tile-container">
          <Col md={4} xs={12} sm={6} className="u-mb--200">
            <ActionTile
              id="billsAndPaymentsTile"
              qa="billsAndPaymentsTile"
              icon="IconDocument2"
              label="Bills and payments"
              infoText="View your billing and payment history."
              navigationLinkText="Bills and payments"
              navigationLink={APP_URLS.BILLS_AND_PAYMENTS}
              isExternalUrl={false}
            />
          </Col>
          {showMakeAPaymentTile() && (
            <Col md={4} xs={12} sm={6} className="u-mb--200">
              <ActionTile
                id="makeAPaymentTile"
                qa="makeAPaymentTile"
                icon="IconPaymentCard"
                label="Make a payment"
                infoText="You can pay your bill online using a credit or debit card."
                navigationLinkText="Make a payment"
                navigationLink={`${YW_URLS.MAKE_A_PAYMENT}?accountReference=${encodeURIComponent(
                  selectedProperty.accountReference,
                )}`}
                isExternalUrl={true}
              />
            </Col>
          )}
          {!selectedProperty.isClosed && (
            <Col md={4} xs={12} sm={6} className="u-mb--200">
              <ActionTile
                id="changeTheWayYouPayTile"
                qa="changeTheWayYouPayTile"
                icon="IconPoundSign"
                label="Change the way you pay"
                infoText="Change how or when you pay."
                navigationLinkText="Change the way you pay"
                navigationLink={`${YW_URLS.PAYMENT_PLAN}?accountReference=${encodeURIComponent(
                  selectedProperty.accountReference,
                )}`}
                isExternalUrl={true}
              />
            </Col>
          )}
          {selectedProperty.isMetered && (
            <Col md={4} xs={12} sm={6} className="u-mb--200">
              <ActionTile
                id="submitMeterReading"
                qa="submitMeterReading"
                icon="IconWashingMachine"
                label="Submit a meter reading"
                infoText={
                  !selectedProperty.isReadyForReading
                    ? "Your meter readings are up to date. You can submit one every 6 months or when you've received an estimated bill."
                    : 'Tell us your meter reading to get an accurate bill.'
                }
                navigationLinkText="Submit a meter reading"
                isExternalUrl={true}
                toggleLabel={!selectedProperty.isReadyForReading ? "Why can't I submit a reading" : ''}
                handleToggle={() => {
                  setModalIsOpen(() => true);
                }}
                navigationLink={`${YW_URLS.SUBMIT_A_METER_READING}?accountReference=${encodeURIComponent(
                  selectedProperty.accountReference,
                )}`}
              />
            </Col>
          )}
          {selectedProperty.isEligibleForPaperlessBilling && (
            <Col md={4} xs={12} sm={6} className="u-mb--200">
              <ActionTile
                id="paper_or_paperless"
                qa="paper_or_paperless"
                icon="IconPaperPlane"
                label="Paper or paperless billing"
                infoText="Get your bills and statements in the post or by email."
                navigationLinkText="Billing preferences"
                isExternalUrl={false}
                navigationLink={APP_URLS.BILL_PREFERENCES}
              />
            </Col>
          )}
          <Col md={4} xs={12} sm={6} className="u-mb--200">
            <ActionTile
              id="addPropertyTile"
              qa="addPropertyTile"
              icon="IconHouse3"
              label="Add a property"
              infoText="Manage another property in your online account."
              navigationLinkText="Add a property"
              isExternalUrl={false}
              navigationLink={APP_URLS.ADD_PROPERTY}
            />
          </Col>
          <Col md={4} xs={12} sm={6} className="u-mb--200">
            <ActionTile
              id="reportAProblemTile"
              qa="reportAProblemTile"
              icon="IconAlertTriangle"
              label="Report a problem"
              infoText="Let us know about a potential problem at your property."
              navigationLinkText="Report a problem"
              isExternalUrl={true}
              navigationLink={YW_URLS.REPORT_A_PROBLEM}
            />
          </Col>
          <Col md={4} xs={12} sm={6} className="u-mb--200">
            <ActionTile
              id="closeAnAccountTile"
              qa="closeAnAccountTile"
              icon="IconTruck"
              label="Close your account"
              infoText={
                "If you're moving out of Yorkshire, or you're no longer responsible for paying the bill, you can let us know using this form. \n\nIf you're moving abroad you'll need to get in touch."
              }
              navigationLinkText="Close my account"
              isExternalUrl={true}
              navigationLink={getMovingHomeURL('move-out', selectedProperty.accountReference)}
            />
          </Col>
          {!isLoggedInMoveWithinVisible && (
            <Col md={4} xs={12} sm={6} className="u-mb--200">
              <ActionTile
                id="moveWithinTile"
                qa="moveWithinTile"
                icon="IconForYourInformation"
                label="Moving within the Yorkshire Water area"
                infoText="If you're moving within our area, all you have to do is fill in this form."
                navigationLinkText="Tell us you're moving"
                isExternalUrl={true}
                navigationLink={YW_URLS.MOVE_WITHIN}
              />
            </Col>
          )}
          {isLoggedInMoveWithinVisible && (
            <Col md={4} xs={12} sm={6} className="u-mb--200">
              <ActionTile
                id="moveWithinTile"
                qa="moveWithinTile"
                icon="IconTruck"
                label="Moving home"
                infoText="Let us know if you're moving within our area"
                navigationLinkText="Tell us about your move"
                isExternalUrl={true}
                navigationLink={getMovingHomeURL('move-within', selectedProperty.accountReference)}
              />
            </Col>
          )}
        </Row>
        {closedProperties.length > 0 && (
          <p className="closed-properties-text">
            Looking for bills and payments from a{' '}
            <NavLink to={APP_URLS.PREVIOUS_PROPERTIES}>previous property?</NavLink>
          </p>
        )}
      </ContentGroup>
      <ModalDialog
        id="more-info-modal"
        qa="more-info-modal"
        isOpen={modalIsOpen}
        title="Why can't I submit a reading?"
        confirmButton={{}}
        cancelButton={{
          label: 'Back',
          action: () => setModalIsOpen(() => false),
        }}
      >
        <p className="u-mb--0">
          We only need a meter reading every 3 months. We aim to read it for you, so you don&apos;t have to. In between
          our reads, we&apos;ll estimate your water use.
        </p>
        <p>You can tell us your meter reading, usually every 6 months when we&apos;re estimating your use.</p>
        <p className="u-mt--300 u-mb--0">
          <strong>Here&apos;s an example:</strong>
        </p>
        <p className="u-mb--300">
          January - we read your meter and update the use on your account.
          <br />
          April - we estimate your water use and you&apos;ll be able to submit your reading.
          <br />
          July - we read your meter and update the water use on your account.
          <br />
          October - we estimate your water use and you&apos;ll be able to submit your reading.
          <br />
        </p>
        <p>
          Find out more about{' '}
          <a
            href={YW_URLS.WATER_METERS}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => setModalIsOpen(() => false)}
          >
            water meters
          </a>{' '}
          and how we bill.
        </p>
      </ModalDialog>
    </HeroLayout>
  );
};

export default Home;
