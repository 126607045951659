export const APPLICATION_NAME = 'Online Account';
export const MAIN_URL = 'http://www.yorkshirewater.com';
export const MAX_PAGINATION_PAGE_SIZE = 10;
export const AUTH = { IDENTIFIER: null };
export const APP_BASENAME = process.env.NODE_ENV === 'production' ? '#{AppBaseName}' : '/account';
export const INACTIVITY_TIMEOUT = 1000 * 60 * 15; // Timeout after 15mins
export const INACTIVITY_TIMEOUT_PROMPT = 1000 * 60; // Prompt user for inactivity 1min before timeout

// export const INACTIVITY_TIMEOUT = 1000 * 60; // TEST - Timeout after 1mins for test purposes
// export const INACTIVITY_TIMEOUT_PROMPT = 1000 * 10; // TEST - Prompt user for inactivity 10secs before timeout for test purposes

export { default as API } from './api';
export { default as TELEMETRY } from './telemetry';
export { envUrl, APP_URLS, ENDPOINTS, AUTH_URLS, YW_URLS } from './urls';
export { authOptions } from './authOptions';
