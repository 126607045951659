import { CUSTOMER_ACTIONS } from './customerActions';

export const RADIO_BOOL = {
  YES: 'yes',
  NO: 'no',
};

export const INITIAL_STATE = {
  loading: false,
  data: {
    title: '',
    forename: '',
    surname: '',
    email: '',
    mobileTelephone: '',
    serviceFeedback: RADIO_BOOL.NO,
    supplyIssues: RADIO_BOOL.NO,
  },
  error: null,
};

export const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CUSTOMER_ACTIONS.UPDATE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case CUSTOMER_ACTIONS.UPDATE_CUSTOMER_RESPONSE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case CUSTOMER_ACTIONS.UPDATE_CUSTOMER_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case CUSTOMER_ACTIONS.RESET: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};
