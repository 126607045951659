import { APP_URLS, YW_URLS } from '../../../../common/settings';
import { getMovingHomeURL, isLoggedInMoveWithinVisible } from '../../../../common/settings/urls';

export const getMenuItems = (selectedProperty, properties, closedProperties) => {
  if (!selectedProperty.accountReference) {
    return [];
  }
  let showMakeAPaymentLink = true;

  if (selectedProperty) {
    if (selectedProperty.isClosed) {
      if (Number(selectedProperty.balanceOutstanding) <= 0) {
        showMakeAPaymentLink = false;
      }
    } else {
      if (selectedProperty.isPaymentPlan) {
        showMakeAPaymentLink = false;
      }
    }
  }
  return [
    {
      id: 'billsAndPayments',
      title: 'Bills and payments',
      url: APP_URLS.BILLS_AND_PAYMENTS,
      isExternalUrl: false,
      isVisible: true,
    },
    {
      id: 'makeAPayment',
      title: 'Make a payment',
      url: `${YW_URLS.MAKE_A_PAYMENT}?accountReference=${encodeURIComponent(selectedProperty.accountReference)}`,
      isExternalUrl: true,
      isVisible: showMakeAPaymentLink,
    },
    {
      id: 'changeTheWayYouPay',
      title: 'Change the way you pay',
      url: `${YW_URLS.PAYMENT_PLAN}?accountReference=${encodeURIComponent(selectedProperty.accountReference)}`,
      isExternalUrl: true,
      isVisible: !selectedProperty.isClosed,
    },
    {
      id: 'billingPreferences',
      title: 'Billing preferences',
      url: APP_URLS.BILL_PREFERENCES,
      isExternalUrl: false,
      isVisible: selectedProperty.isEligibleForPaperlessBilling,
    },
    {
      id: 'submitAMeterReading',
      title: 'Submit a meter reading',
      url: `${YW_URLS.SUBMIT_A_METER_READING}?accountReference=${encodeURIComponent(
        selectedProperty.accountReference,
      )}`,
      isExternalUrl: true,
      isVisible: selectedProperty.isMetered && selectedProperty.isReadyForReading,
    },
    {
      id: 'yourProperties',
      title: 'Your properties',
      url: APP_URLS.PROPERTIES,
      isExternalUrl: false,
      isVisible: properties.length > 1,
    },
    {
      id: 'addAProperty',
      title: 'Add a property',
      url: APP_URLS.ADD_PROPERTY,
      isExternalUrl: false,
      isVisible: true,
    },
    {
      id: 'reportAProblem',
      title: 'Report a problem',
      url: YW_URLS.REPORT_A_PROBLEM,
      isExternalUrl: true,
      isVisible: true,
    },
    {
      id: 'previousProperties',
      title: 'Previous properties',
      url: APP_URLS.PREVIOUS_PROPERTIES,
      isExternalUrl: false,
      isVisible: closedProperties.length > 0,
    },
    {
      id: 'closeAnAccount',
      title: 'Close your account',
      url: getMovingHomeURL('move-out', selectedProperty.accountReference),
      isExternalUrl: true,
      isVisible: true,
    },
    {
      id: 'moveWithin',
      title: 'Moving home',
      url: getMovingHomeURL('move-within', selectedProperty.accountReference),
      isExternalUrl: true,
      isVisible: isLoggedInMoveWithinVisible,
    },
  ];
};
