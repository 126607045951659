import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ContentGroup, Breadcrumbs, Loader } from 'yw-ui.v2';
import { usePageLoaded } from '../../common/hooks';
import { APP_URLS } from '../../common/settings';
import { tokenSelector } from '../../store/auth/selectors';
import { history } from '../../store';
import { getCustomerDetails } from '../../store/customer/customerActions';
import { customerIsLoadingSelector, customerResponseSelector } from '../../store/customer/customerSelectors';
import {
  closedPropertiesListSelector,
  propertiesListSelector,
  selectedPropertySelector,
} from '../../store/properties/propertiesSelectors';
import { PageContentHeader } from '../components';
import { HeroLayout } from '../layouts';

const ChangeEmail = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const { email } = useSelector(customerResponseSelector);
  const customerIsLoading = useSelector(customerIsLoadingSelector);
  const selectedProperty = useSelector(selectedPropertySelector);
  const { properties } = useSelector(propertiesListSelector);
  const { closedProperties = properties } = useSelector(closedPropertiesListSelector);

  const pageLoaded = usePageLoaded();

  useEffect(() => {
    if (!pageLoaded) {
      if (token && !email) {
        dispatch(getCustomerDetails(token, history));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLoaded, token, email]);

  const getBreadCrumbs = () => {
    if (selectedProperty.accountReference) {
      return [
        { label: 'Home', to: APP_URLS.ROOT },
        { label: 'My profile', to: APP_URLS.MY_PROFILE },
        { label: 'Change email address' },
      ];
    } else if (properties.length > 0) {
      return [
        { label: 'Your properties', to: APP_URLS.PROPERTIES },
        { label: 'My profile', to: APP_URLS.MY_PROFILE },
        { label: 'Change email address' },
      ];
    } else if (closedProperties.length > 0) {
      return [
        { label: 'Previous properties', to: APP_URLS.PROPERTIES },
        { label: 'My profile', to: APP_URLS.MY_PROFILE },
        { label: 'Change email address' },
      ];
    }
    return [
      { label: 'Add property', to: APP_URLS.ADD_PROPERTY },
      { label: 'My profile', to: APP_URLS.MY_PROFILE },
      { label: 'Change email address' },
    ];
  };

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className={`u-anim--fade-in contact-information-wrapper`}>
        <PageContentHeader
          title="Change your email address"
          breadcrumb={<Breadcrumbs showIcon={true} crumbs={getBreadCrumbs()} />}
        />
        {customerIsLoading && <Loader message="Loading your details..." />}
      </ContentGroup>
    </HeroLayout>
  );
};

export default ChangeEmail;
