import { useState, useEffect } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const useTelemetry = (instrumentationKey, isActive, history, replaceTitle = null) => {
  const [lastUri, setLastUri] = useState(null);
  const [appInsights, setAppInsights] = useState(null);

  useEffect(() => {
    if (isActive && history && !appInsights) {
      const reactPlugin = new ReactPlugin();

      const appInsights = new ApplicationInsights({
        config: {
          disableFetchTracking: false,
          extensionConfig: {
            [reactPlugin.identifier]: { history: history },
          },
          extensions: [reactPlugin],
          instrumentationKey,
          maxBatchInterval: 0,
        },
      });
      appInsights.loadAppInsights();
      appInsights.addTelemetryInitializer((envelope) => {
        if (envelope.baseType === 'PageviewData') {
          // dont track same page again
          if (lastUri === envelope.baseData.uri) {
            return false;
          }

          // Removes base document title fro start of logged page name
          if (replaceTitle) {
            envelope.baseData.name = envelope.baseData.name.replace(replaceTitle, '');
          }

          setLastUri(envelope.baseData.uri);
        }
        return true;
      });
      setAppInsights(appInsights);
    }
  }, [appInsights, history, instrumentationKey, isActive, lastUri, replaceTitle, setAppInsights]);

  return appInsights;
};

export default useTelemetry;
