export const PAYMENT_FREQUENCY = {
  MONTHLY: 'Monthly',
  ANNUALLY: 'Annually',
  HALF_YEARLY: 'HalfYearly',
  FORTNIGHTLY: 'Fortnightly',
  QUARTERLY: 'Quarterly',
  WEEKLY: 'Weekly',
  PER_BILL: 'PerBill',
  ONE_OFF: 'OneOff',
  FOUR_WEEKLY: 'FourWeekly',
};
