import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumbs, Message, FluidForm, FluidField, ContentGroup, Loader } from 'yw-ui.v2';
import { HeroLayout } from '../layouts';
import { ButtonControls, PageContentHeader } from '../components';
import { APP_URLS } from '../../common/settings';
import { customerIsLoadingSelector, customerResponseSelector } from '../../store/customer/customerSelectors';
import { getCustomerDetails, submitCustomerPreferences } from '../../store/customer/customerActions';
import { tokenSelector } from '../../store/auth/selectors';
import { history } from '../../store';
import { RADIO_BOOL } from '../../store/customer/customerReducer';
import { usePageLoaded } from '../../common/hooks';
import {
  closedPropertiesListSelector,
  propertiesListSelector,
  selectedPropertySelector,
} from '../../store/properties/propertiesSelectors';

const ContactInformation = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const { forename, surname, mobileTelephone, serviceFeedback, supplyIssues } = useSelector(customerResponseSelector);
  const customerIsLoading = useSelector(customerIsLoadingSelector);
  const selectedProperty = useSelector(selectedPropertySelector);
  const { properties } = useSelector(propertiesListSelector);
  const { closedProperties = properties } = useSelector(closedPropertiesListSelector);

  const pageLoaded = usePageLoaded();
  const [isSUbmittingUpdate, setIsSUbmittingUpdate] = useState(false);

  useEffect(() => {
    if (!pageLoaded) {
      if (token) {
        dispatch(getCustomerDetails(token, history));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLoaded, token]);

  const getBreadCrumbs = () => {
    if (selectedProperty.accountReference) {
      return [
        { label: 'Home', to: APP_URLS.ROOT },
        { label: 'My profile', to: APP_URLS.MY_PROFILE },
        { label: 'Update contact information' },
      ];
    } else if (properties.length > 0) {
      return [
        { label: 'Your properties', to: APP_URLS.PROPERTIES },
        { label: 'My profile', to: APP_URLS.MY_PROFILE },
        { label: 'Update contact information' },
      ];
    } else if (closedProperties.length > 0) {
      return [
        { label: 'Previous properties', to: APP_URLS.PROPERTIES },
        { label: 'My profile', to: APP_URLS.MY_PROFILE },
        { label: 'Update contact information' },
      ];
    }
    return [
      { label: 'Add property', to: APP_URLS.ADD_PROPERTY },
      { label: 'My profile', to: APP_URLS.MY_PROFILE },
      { label: 'Update contact information' },
    ];
  };

  const onGoBack = () => {
    history.goBack();
  };

  const onSubmit = (value) => {
    const params = {
      serviceFeedback: value.serviceFeedback === RADIO_BOOL.YES ? true : false,
      supplyIssues: value.supplyIssues === RADIO_BOOL.YES ? true : false,
    };
    setIsSUbmittingUpdate(true);
    dispatch(
      submitCustomerPreferences(params, token, history, (response) => {
        setIsSUbmittingUpdate(false);
        history.push(APP_URLS.MY_PROFILE);
      }),
    );
  };

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className={`u-anim--fade-in contact-information-wrapper`}>
        <PageContentHeader
          title="Update contact information"
          breadcrumb={<Breadcrumbs showIcon={true} crumbs={getBreadCrumbs()} />}
        />
        {customerIsLoading && (
          <Loader message={isSUbmittingUpdate ? 'Updating your details...' : 'Loading your details...'} />
        )}
        <Message
          title="Please note:"
          id="contact-information-notice"
          qa="contact-information-notice"
          className="u-p--300 u-mt--300 u-mb--300"
        >
          <p>Amending your personal information will not update the name on your bill.</p>
        </Message>

        <ContentGroup marginTop="300">
          <FluidForm
            initialValues={{ forename, surname, mobileTelephone, serviceFeedback, supplyIssues }}
            onSubmit={onSubmit}
            suppressHtmlValidation
          >
            <FluidField
              label="Your first name"
              id="forename"
              name="forename"
              qa="forename"
              data-testid="forename"
              className="c-input--col-4"
              component="text"
              isRequiredMarker
              disabled
            />
            <FluidField
              label="Your last name"
              id="surname"
              name="surname"
              qa="surname"
              data-testid="surname"
              className="c-input--col-4"
              component="text"
              isRequiredMarker
              disabled
            />
            <FluidField
              label="Your phone number"
              id="mobileTelephone"
              name="mobileTelephone"
              qa="mobileTelephone"
              data-testid="mobileTelephone"
              className="c-input--chars-15"
              component="text"
              isRequiredMarker
              disabled
            />
            <FluidField
              component="radiotile"
              id="serviceFeedback"
              qa="serviceFeedback"
              name="serviceFeedback"
              options={[
                { id: RADIO_BOOL.YES, label: 'Yes' },
                { id: RADIO_BOOL.NO, label: 'No' },
              ]}
              label="Customer service feedback messages"
            />
            <FluidField
              component="radiotile"
              id="supplyIssues"
              qa="supplyIssues"
              name="supplyIssues"
              options={[
                { id: RADIO_BOOL.YES, label: 'Yes' },
                { id: RADIO_BOOL.NO, label: 'No' },
              ]}
              label="Notice of water supply issues in your area"
            />

            <ButtonControls nextLabel="Update" onBack={onGoBack} />
          </FluidForm>
        </ContentGroup>
      </ContentGroup>
    </HeroLayout>
  );
};

export default ContactInformation;
