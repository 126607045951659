import React, { useEffect } from 'react';
import { ContentGroup, AccordionSingle, Breadcrumbs, Heading } from 'yw-ui.v2';
import { HeroTiles, PageContentHeader } from '../components';
import { useSelector, useDispatch } from 'react-redux';
import { HeroLayout } from '../layouts';
import { closedPropertiesListSelector, selectedPropertySelector } from '../../store/properties/propertiesSelectors';
import { tokenSelector } from '../../store/auth/selectors';
import { getBillInPDF, getBillsAndPayments } from '../../store/billsPayments/billsPaymentsActions';
import {
  billsPaymentsResponseSelector,
  downloadedBillPDFSelector,
} from '../../store/billsPayments/billsPaymentsSelector';
import { BillItem } from './components';
import { downloadPDFDocument, getLongDateFormat, stripLeadingSlashFromLink } from '../../common/functions';
import { NavLink } from 'react-router-dom';
import { APP_URLS } from '../../common/settings';
import { usePageLoaded } from '../../common/hooks';
import { history } from '../../store';

const BillsAndPayments = () => {
  const LATEST_PAYMENTS_COUNT = 4;
  const dispatch = useDispatch();
  const selectedProperty = useSelector(selectedPropertySelector);
  const { properties: closedProperties } = useSelector(closedPropertiesListSelector);
  const { accountReference } = useSelector(selectedPropertySelector);
  const token = useSelector(tokenSelector);
  const {
    billsHistory: { bills },
    paymentsHistory: { payments },
  } = useSelector(billsPaymentsResponseSelector);
  const downloadedBillPDFs = useSelector(downloadedBillPDFSelector);
  const pageLoaded = usePageLoaded();

  useEffect(() => {
    if (!pageLoaded && token) {
      dispatch(getBillsAndPayments(accountReference, 10, token, history));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLoaded, accountReference, token]);

  const handleBillClicked = (link, name, date) => {
    const fileName = `${name}_${getLongDateFormat(date)}.pdf`;
    if (downloadedBillPDFs[link]) {
      downloadPDFDocument(fileName, downloadedBillPDFs[link]);
    } else {
      dispatch(getBillInPDF(fileName, link, token));
    }
  };

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className="u-anim--fade-in bills-payments-wrapper">
        <PageContentHeader
          title="Bills &amp; payments"
          breadcrumb={
            <Breadcrumbs showIcon={true} crumbs={[{ label: 'Home', to: '/' }, { label: 'Bills & payments' }]} />
          }
        />
        <HeroTiles selectedProperty={selectedProperty} />
        <Heading size="lg" title="Bills" />
        <p>Download your bills to read them or print a copy.</p>
        <Heading size="sm" title="Latest bill" />
        {bills.length <= 0 && <p>No bills available.</p>}
        {bills.length > 0 && (
          <BillItem
            title={bills[0].description}
            date={bills[0].date}
            onClick={() =>
              handleBillClicked(stripLeadingSlashFromLink(bills[0].link), bills[0].description, bills[0].date)
            }
          />
        )}
        {bills.length > 1 && (
          <AccordionSingle
            id="older-bills"
            qa="older-bills"
            className="u-mt--300 older-bills-list"
            title="View older bills"
          >
            {bills.slice(1).map((oldBill) => (
              <BillItem
                key={oldBill.id}
                title={oldBill.description}
                date={oldBill.date}
                onClick={() => handleBillClicked(oldBill.link, oldBill.description, oldBill.date)}
              />
            ))}
          </AccordionSingle>
        )}
        <Heading size="lg" title="Payments" className="u-mt--400 u-mb--300" />
        <ContentGroup className="payments-table-wrapper">
          <table className="table payments-table">
            <thead>
              <tr>
                <td>
                  <p>Date</p>
                </td>
                <td>
                  <p>Description</p>
                </td>
                <td>
                  <p>Amount</p>
                </td>
              </tr>
            </thead>
            <tbody>
              {payments.length <= 0 && (
                <tr>
                  <td colSpan={3}>
                    <p className="no-payments">No payments available.</p>
                  </td>
                </tr>
              )}
              {payments
                .map((payment, index) => (
                  <tr key={index} className={Number(index) % 2 === 0 ? 'odd' : ''}>
                    <td>{getLongDateFormat(payment.date)}</td>
                    <td>{payment.description}</td>
                    <td>&pound;{Number(Math.abs(payment.amount)).toFixed(2)}</td>
                  </tr>
                ))
                .filter((_, index) => index < LATEST_PAYMENTS_COUNT)}
            </tbody>
          </table>
          {payments.length > LATEST_PAYMENTS_COUNT && (
            <AccordionSingle
              id="previous-payments"
              qa="previous-payments"
              className="u-mt--100 u-mb--100 previous-payments-list"
              title="View previous payments"
            >
              <table className="table payments-table">
                <tbody>
                  {payments
                    .map((payment, index) => (
                      <tr key={index} className={Number(index) % 2 === 0 ? 'odd' : ''}>
                        <td>{getLongDateFormat(payment.date)}</td>
                        <td>{payment.description}</td>
                        <td>&pound;{Number(Math.abs(payment.amount)).toFixed(2)}</td>
                      </tr>
                    ))
                    .filter((_, index) => index >= LATEST_PAYMENTS_COUNT)}
                </tbody>
              </table>
            </AccordionSingle>
          )}
        </ContentGroup>
        {closedProperties && closedProperties.length > 0 && (
          <>
            <div className="c-divider u-mt--200" />
            <p>
              Looking for bills and payments from a{' '}
              <NavLink to={APP_URLS.PREVIOUS_PROPERTIES}>previous property?</NavLink>
            </p>
          </>
        )}
      </ContentGroup>
    </HeroLayout>
  );
};

export default BillsAndPayments;
