import React from 'react';
import PropTypes from 'prop-types';
import { ContentGroup, Heading } from 'yw-ui.v2';

const PageHeading = ({ title, ...other }) => (
  <ContentGroup {...other}>
    <Heading priority={1} size="xl" id="page-heading_heading" qa="page-heading_heading">
      {title}
    </Heading>
  </ContentGroup>
);

PageHeading.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageHeading;
