import { BILLS_PAYMENTS_ACTIONS } from './billsPaymentsActions';

export const INITIAL_STATE = {
  loading: false,
  error: null,
  data: {
    displayAccountReference: '',
    accountReference: '',
    accountStatus: '',
    lastPaymentDate: '',
    lastPaymentValue: 0,
    paymentMethod: '',
    balanceOutstanding: 0,
    paymentFrequency: '',
    billsHistory: {
      bills: [],
      mostRecentActualBillId: '',
    },
    paymentsHistory: {
      payments: [],
    },
  },
  downLoadedBillPDF: {},
};

export const billsPaymentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BILLS_PAYMENTS_ACTIONS.UPDATE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case BILLS_PAYMENTS_ACTIONS.UPDATE_BILLS_PAYMENTS_RESPONSE: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case BILLS_PAYMENTS_ACTIONS.UPDATE_BILLS_PAYMENTS_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case BILLS_PAYMENTS_ACTIONS.UPDATE_DOWNLOADED_BILL_PDF: {
      return {
        ...state,
        downLoadedBillPDF: {
          ...state.downLoadedBillPDF,
          ...action.payload,
        },
      };
    }
    case BILLS_PAYMENTS_ACTIONS.RESET_BILLS_PAYMENTS: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};
