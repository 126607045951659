import React from 'react';
import { ContentGroup, Button } from 'yw-ui.v2';
import { useAppAuth } from '../../common/hooks';
import { YW_URLS } from '../../common/settings';
import { PageContentHeader } from '../components';
import { HeroLayout } from '../layouts';

const AccountDisabled = () => {
  const doLogOut = useAppAuth();

  const onLogout = () => {
    doLogOut();
  };

  return (
    <HeroLayout heroCollapse={true}>
      <ContentGroup className="u-anim--fade-in account-disabled">
        <PageContentHeader title="Your online account hasn't been used for a while" breadcrumb="" />
        <p>
          If you&apos;re still using the same email address your account was registered with please{' '}
          <a href={YW_URLS.GET_IN_TOUCH} target="_blank" rel="noopener noreferrer">
            contact us
          </a>{' '}
          so we can get this sorted for you.
        </p>
        <p>
          If your email address has changed please register for{' '}
          <a href={YW_URLS.REGISTER_AN_ACCOUNT} target="_blank" rel="noopener noreferrer">
            a new online account
          </a>
          . You&apos;ll need your customer reference number from the last bill or statement you received.
        </p>
        <Button
          className="u-mt--300"
          type="button"
          label="Log out"
          state="default-outline"
          onClick={onLogout}
          id="log-out-button"
          qa="log-out-button"
        />
      </ContentGroup>
    </HeroLayout>
  );
};

export default AccountDisabled;
