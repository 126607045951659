import RestClient from '../../common/modules/rest';
import { API } from '../../common/settings';
import { createToken } from '../../common/functions';

const rest = new RestClient();
const validateReferenceEndPoint = API.API_ENDPOINTS.VALIDATE_REFERENCE;
const addPropertyEndPoint = API.API_ENDPOINTS.ADD_PROPERTY;

export const validateCustomerReferenceNumberRequest = (customerReferenceNumber, token) =>
  rest.get(`${validateReferenceEndPoint}?reference=${customerReferenceNumber}`, createToken(token));

export const submitAddPropertyRequest = (params, token) => rest.post(addPropertyEndPoint, params, createToken(token));
