// API ENDPOINTS
export const BASE_URL = process.env.NODE_ENV === 'production' ? '#{ApiBaseUrl}' : 'https://mydev.yorkswater.com/api/';

// Endpoints
export const API_BASE_URL = `${BASE_URL}account`;
export const REGISTRATION_BASE_URL = `${BASE_URL}registration`;

export const API_ENDPOINTS = {
  BILLS_PAYMENTS: `${API_BASE_URL}/payments`,
  CUSTOMER_DETAIL: `${API_BASE_URL}/customer/detail`,
  CUSTOMER_PREFERENCES: `${API_BASE_URL}/customer/preferences`,
  PROPERTIES: `${API_BASE_URL}/properties`,
  PROPERTY: `${API_BASE_URL}/properties/detail`,
  SWITCH_PAPERLESS: `${API_BASE_URL}/properties/paperless`,
  VALIDATE_REFERENCE: `${API_BASE_URL}/properties/validatereference`,
  ADD_PROPERTY: `${API_BASE_URL}/properties/addproperty`,
  PENDED_ACCOUNT_SETUP: `${REGISTRATION_BASE_URL}/autoenrol/pending`,
};

export default { API_BASE_URL, API_ENDPOINTS, BASE_URL };
