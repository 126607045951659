export const constraint = (selectedBillPreference, isBillingPaperless, isEligibleForPaperlessBilling) => ({
  selectedBillPreference: {
    presence: { allowEmpty: false },
    noSelectionValidation: {
      selectedBillPreference,
      isBillingPaperless,
      isEligibleForPaperlessBilling,
      message: {
        postError: 'You already receive your bill by post.',
        emailError: 'You already receive a paperless bill.',
      },
    },
    notEligibleValidator: {
      selectedBillPreference,
      isBillingPaperless,
      isEligibleForPaperlessBilling,
      message: 'You are not eligible for paperless billing.',
    },
  },
});
