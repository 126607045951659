import { ENDPOINTS, AUTH_URLS, YW_URLS, APP_URLS } from './';

export const authOptions = {
  accessTokenExpiringNotificationTime: 60,
  authority: AUTH_URLS.IDS,
  automaticSilentRenew: true,
  checkSessionInterval: 2000,
  client_id: 'css-onlineaccount-fe',
  loadUserInfo: false,
  metadata: {
    authorization_endpoint: `${AUTH_URLS.IDS}/connect/authorize`,
    end_session_endpoint: `${AUTH_URLS.IDS}/connect/endsession`,
    issuer: AUTH_URLS.IDS,
    jwks_uri: `${AUTH_URLS.IDS}/.well-known/openid-configuration/jwks`,
    token_endpoint: `${AUTH_URLS.IDS}/${ENDPOINTS.TOKEN}`,
  },
  post_logout_redirect_uri: YW_URLS.HOME,
  redirect_uri: `${AUTH_URLS.BASE}callback/response`,
  response_type: 'code',
  revokeAccessTokenOnSignout: true,
  scope: 'openid user-names css-onlineaccount-api css-registration-api',
  signingKeys: [
    {
      alg: 'RS256',
      // eslint-disable-next-line
      e: 'AQAB',
      kid: 'B3A04C40D7F149254141FB8FE1EE22A7550197A8',
      kty: 'RSA',
      n:
        '6rQZuUU4nTS8PimMeq_66TXvLFhKEfFeZaZG_l3bYqF6RMSXxmAFlLByP61PDZzW3yOzrArPSnK6MKetMVl4U0fS-pIb_mQGoGUVz6P7qoO4ZT1uuwD6PxzvMShhIYSSqmaZwrPMrx3SAby1DPC6oltTETQx1vtM9K4Q91F6Aq4tZMqaN8hbJmTi-WyZuOSCHVqUowWmzd2IR6EIHrgxXpdPNOPnLGIa-Z_6qR0m7MBeFpUBz451ljP-dOSmzjGGvtNprDMWm8CqwWAx-y8bzE9ggeZMZEu2xupu2kV8L3K_2GPLA3RyGwHP9ZgRKh1AsDgbIU65cIm6x8b34nAeJQ',
      use: 'sig',
      x5c: [
        'MIIDQjCCAiqgAwIBAgIQdxqCyI9hb4NHNQ9ayeGRRTANBgkqhkiG9w0BAQsFADAiMSAwHgYDVQQDDBdsb2dpbmRldi55b3Jrc3dhdGVyLmNvbTAeFw0xODAzMjMxNTQ5MzJaFw0yODAzMjMxNTU5MzJaMCIxIDAeBgNVBAMMF2xvZ2luZGV2Lnlvcmtzd2F0ZXIuY29tMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6rQZuUU4nTS8PimMeq/66TXvLFhKEfFeZaZG/l3bYqF6RMSXxmAFlLByP61PDZzW3yOzrArPSnK6MKetMVl4U0fS+pIb/mQGoGUVz6P7qoO4ZT1uuwD6PxzvMShhIYSSqmaZwrPMrx3SAby1DPC6oltTETQx1vtM9K4Q91F6Aq4tZMqaN8hbJmTi+WyZuOSCHVqUowWmzd2IR6EIHrgxXpdPNOPnLGIa+Z/6qR0m7MBeFpUBz451ljP+dOSmzjGGvtNprDMWm8CqwWAx+y8bzE9ggeZMZEu2xupu2kV8L3K/2GPLA3RyGwHP9ZgRKh1AsDgbIU65cIm6x8b34nAeJQIDAQABo3QwcjAOBgNVHQ8BAf8EBAMCBaAwHQYDVR0lBBYwFAYIKwYBBQUHAwIGCCsGAQUFBwMBMCIGA1UdEQQbMBmCF2xvZ2luZGV2Lnlvcmtzd2F0ZXIuY29tMB0GA1UdDgQWBBRnWqkzGgN0UYJJjBlaV/ZVTeT+MDANBgkqhkiG9w0BAQsFAAOCAQEAiMycHZcx/0Ii000YQSFACeTmDtr7vvXzZWmtDK9LQkg6fKTViH5z5Sj/5M5PzZSlQEhF9Tl95jI89rYYHm8VeecN3d8tAXFMvrw2V5exBsJsIs1hP75r0fxNoC2rdU7UYjiXn6eWCCfQfq5zrepThcRMOZCTXn4DRUouRmbkyrHVNmi3n3dQYZ0Vn9wFP1tsagVXIFNip7CpTGUEhsHp9nq2g6VBg0xt/Mb7wGM1oQl11+Z4hrA1vInNzwTh9M80xxuquYbG1zFJwPrki3YDt3rfOLZVd0QUZxFI0EykF5NN47WX90eQQNNkttLGiu+07k9Pz2by//JCHu9gXZwE3g==',
      ],
      x5t: 's6BMQNfxSSVBQfuP4e4ip1UBl6g',
    },
  ],
  silent_redirect_uri: `${AUTH_URLS.BASE}auth/silent/`,
  unauthorised_uri: APP_URLS.SOMETHING_WENT_WRONG,
};
